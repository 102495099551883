.servicios {
  &__content {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  &__claim {
    width: 97% !important;
  }

  &__descripcion {
    width: 60%;
    font-size: 1.3rem;
    line-height: 1.5;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}

.listado {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
  }

  &__icono {
    margin-right: 2.5rem;
    margin-top: 0.5rem;
    img {
      width: 65px;
      height: auto;
    }
    @media screen and (max-width: 575px) {
      margin-right: 1.5rem;
      margin-top: 0.5rem;
      img {
        width: 56px;
      }
    }
  }

  &__info {
  }

  &__titulo {
    font-size: 1.1rem;
    color: $color-principal-dark;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  &__descripcion {
  }
}
