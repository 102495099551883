$color-principal: #f00303;
$color-principal-dark: #c80101;
$color-secundario: #8a7154;
$color-alternativo: #fda11e;

$color-eurorepar: #ff4b00;

$color-whatsapp: #128c7e;

$color-gris-01: #ebebeb;
$color-gris-02: #555555;
$color-gris-03: rgba(0, 0, 0, 0.7);
$color-gris-04: rgba(0, 0, 0, 1);

$color-background: rgba(255, 255, 255, 1);

$font-size-big: 1.2rem;
$font-size-bigger: 1.25rem;
$font-size: 1rem;
$font-size-small: 0.9rem;
$font-size-smaller: 0.7rem;

$font-weight: 400;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-02: 0 0 30px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius: $font-size / 2;

$trans: 0.2s all ease-in-out;

$sp-05: 1.5rem;
$sp-1: 3rem;
$sp-2: 6rem;
$sp-3: 10rem;

$typo-principal: urw-form, sans-serif;
$typo-secundaria: urw-form, sans-serif;

:root {
  --app-height: 100%;
}
