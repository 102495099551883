.cta {
  &--rojo {
    background-color: $color-principal;
  }
  &__content {
    display: grid;
    grid-template-columns: 6fr 3fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      gap: 1rem;
      grid-template-columns: 1fr;
    }
  }

  &__claim {
    font-size: 3.75rem;
    text-transform: uppercase;
    line-height: 1.2;
    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
    span {
      color: $color-principal;
    }
    &--rojo {
      span {
        color: $color-background;
      }
    }
  }

  &__action {
    // text-align: right;
    width: 60%;
    margin-left: auto;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    a {
      display: inline-block;
    }
  }
  &__descripcion {
    margin-bottom: 2rem;
    &--rojo {
      color: $color-background;
    }
  }
}
