.blog-listado {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

  &__thumbnail {
    margin-bottom: 0.5rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.5rem;
    }
    img {
      width: 100%;
      height: auto;
      margin-bottom: 1.2rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.8rem;
      }
    }
  }

  &__categoria {
    margin-bottom: 0.2rem;
  }

  &__titulo {
    @media screen and (max-width: 575px) {
      font-size: 1rem;
    }
    a {
      font-weight: bold;
      font-size: 1.3rem;
      display: inline-block;
      line-height: 1.3;
      @media screen and (max-width: 575px) {
      }
    }
  }
}

.page {
  &__entry {
    h2,
    p,
    ul,
    ol,
    table {
      margin-bottom: 1.8rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 1.2rem;
      }
    }
    h2 {
      font-weight: bold;
    }
    h3 {
      color: $color-principal;
    }
  }
}

.post {
  .wp-block-button {
    &__link {
      background-color: $color-principal;
      color: $color-background;
      text-decoration: none;
      border-radius: 50px;
      font-size: $font-size-small;
    }
  }
  .wc-block-grid {
    &__product {
      padding: 1rem;
      background-color: $color-gris-01;
      @media screen and (min-width: 768px) {
        margin: 0.5rem;
        border: 0;
        flex: 0 0 30% !important;
        max-width: 30% !important;
      }
      &__product-image {
        text-align: center;
        img {
          @media screen and (max-width: 575px) {
            width: 80% !important;
          }
        }
      }
    }
    &__product-price {
      margin-bottom: 0.5rem !important;
    }
    &__product-link {
      text-decoration: none !important;
    }
  }

  &__title {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }
  &__meta {
    margin-bottom: 1rem;
    text-align: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
  }

  &__autor {
    text-align: center;
    margin-bottom: $sp-2;
    @media screen and (max-width: 575px) {
      margin-bottom: $sp-1;
    }
  }

  &__entry {
    margin-bottom: $sp-2;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba($color: #000, $alpha: 0.9);
    h2 {
      font-size: 2.3rem;
      margin: 2.5rem 0rem 1rem 0;
      color: $color-principal;
    }
    h3 {
      font-size: 1.4rem;
      font-weight: 400;
      margin: 2.5rem 0rem 1rem 0;
      text-decoration: underline;
    }
    h4 {
      font-size: 1.2rem;
      font-style: italic;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.3;
      margin: 2.5rem 0rem 1rem 0;
    }
    figure {
      margin: 2rem 0;
    }
    figcaption {
      font-size: 0.7rem;
    }
    img {
      width: 100%;
      height: auto;
    }
    p,
    ul,
    ol {
      margin-bottom: 1.8rem;
      line-height: 1.7;
      font-size: 1rem;
    }
    blockquote.wp-block-quote {
      text-align: center !important;
      padding: 1.3rem;
      @media screen and (max-width: 575px) {
        font-size: 1.1rem;
        margin-left: 0rem;
      }
      p {
        font-size: 1.1rem;
        line-height: 1.6;
        margin-bottom: 1.2rem;
        color: $color-principal-dark;
        text-align: center;
      }
      cite {
        font-size: $font-size-small;
        font-weight: 400;
        margin-bottom: 0.2 !important;
        font-style: normal;
        font-weight: bold;
        background-color: rgba($color: #000000, $alpha: 0.1);
        padding: 1px 9px;
        border-radius: 3px;
        @media screen and (max-width: 575px) {
          padding: 0;
          background-color: initial;
        }
      }
    }

    .wp-block-pullquote {
      background-color: rgba($color: #f4f4e4, $alpha: 0.9) !important;
      border-width: 2px 0;
      border-color: rgba($color: #cacab0, $alpha: 0.9);
      border-style: solid;
      blockquote {
        cite {
          font-size: $font-size-small;
          font-weight: 400;

          font-style: normal;
          font-weight: bold;
        }
      }
    }

    a {
      color: $color-principal;
      text-decoration: underline;
    }
  }
}

.autor {
  display: inline-flex;
  &__avatar {
    margin-right: 1rem;
    img {
      border-radius: 50px;
      width: 60px;
      height: auto;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  &__name {
    font-weight: bold;
  }
}

.blog-lista {
  background-color: $color-gris-01;
  padding: 4rem 0;
  @media screen and (max-width: 575px) {
    padding: 2rem 0;
  }
}

.blog-listado {
  &__container {
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__titular {
    margin-bottom: 2rem;
    font-size: 2rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }
  a {
    display: inline-block;
  }
  &__item {
  }
  &__thumb-link {
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      font-size: 0.7rem;
    }
  }
  &__desc {
  }
  &__category {
    font-size: $font-size-small;
  }
  &__titulo {
    font-size: 1.1rem;
    @media screen and (max-width: 575px) {
      font-size: 0.9rem;
    }
  }
  &__lectura {
    @media screen and (max-width: 575px) {
      font-size: $font-size-smaller;
    }
  }
}
