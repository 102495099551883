@mixin input-buttons {
  font-size: 0.9rem;
  border-radius: 0.35rem;
  padding: 8px 20px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: $trans;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  // text-transform: uppercase;
  @media (max-width: 575px) {
    padding: 0.75rem 1.2rem;
  }
  @media (max-width: 390px) {
    padding: 0.7rem 1rem;
    font-size: 0.8rem;
  }
  .bi {
    margin-left: 5px;
  }
}

@mixin input-buttons-lg {
  font-size: 1rem;
  padding: 8px 30px;
}

@mixin input-buttons-sm {
  padding: 0.55rem 1.2rem;
  letter-spacing: 0px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: $trans;
}

@mixin input-buttons-hover {
  // transform: translateY(-2px);
  box-shadow: $box-shadow-hover;
  transition: $trans;
}

@mixin cart-count($top, $right, $size) {
  display: inline-block;
  position: absolute;
  background-color: $color-secundario;
  padding: 0px 2px;
  border-radius: 3px;
  text-align: center;
  right: $right;
  top: $top;
  color: #fff;
  font-size: $size;
}
