.wpcf7 {
  margin: 1.5rem 0;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: $font-size-small;
  color: $color-gris-04;
  color: rgba(0, 0, 0, 0.4);

  .wpcf7-list-item {
    margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.gdpr-text {
  p {
    font-size: 0.6rem;
  }
}
