.page-title {
  background-color: $color-principal;
  padding: 3rem 0rem;
  @media screen and (max-width: 575px) {
    padding: 1rem 0;
  }
  &__h {
    margin-bottom: 0rem;
    font-size: 3.5rem;
    font-weight: 500;
    color: $color-background;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.2rem;
      font-size: 2rem;
      line-height: 1;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 0.5rem;
      font-size: 1.8rem;
    }
    span {
      padding-left: 1rem;
      @media screen and (max-width: 575px) {
        font-size: $font-size-small;
      }
    }
  }
  &__h2 {
  }
  &__breadcrumbs {
    color: $color-background;
    margin-bottom: $font-size / 2;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      font-size: 0.7rem;
      margin-bottom: 5px;
      // text-transform: uppercase;
    }
    a {
      font-weight: 500;
      color: $color-background;
      &:hover {
        color: $color-gris-04;
      }
    }
  }
}

.single-product .page-title {
  padding-top: 1.5rem;
  background: $color-background;
  padding-bottom: 0.5rem;
  &__breadcrumbs {
    color: $color-gris-04 !important;
    a {
      color: $color-gris-03;
    }
  }
  @media screen and (max-width: 575px) {
    padding-top: 5.5rem;
  }
}
