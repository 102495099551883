/* SCSS Botones
--------------------------------------------------- */

.link-underline {
  text-decoration: underline;
}

.boton {
  @include input-buttons;
  &:hover {
    @include input-buttons-hover;
  }
  .bi {
    display: inline-flex;
    align-items: center;
  }
}

.boton--lg {
  @include input-buttons-lg;
}

.boton--sm {
  @include input-buttons-sm;
}

.boton--block {
  text-align: center;
  display: block;
  width: 100%;
}

.boton--principal {
  background-color: $color-principal;
  color: $color-background;
  border-color: $color-principal;
  &:hover {
    background-color: $color-principal-dark !important;
    border-color: $color-principal-dark;
    color: $color-background;
  }
}

.boton--blanco {
  background-color: $color-background !important;
  color: $color-principal !important;

  &:hover {
    background-color: $color-background !important;
    color: $color-gris-04;
  }
}

.boton--outline-blanco {
  @include input-buttons;
  background-color: initial !important;
  color: $color-background;
  border-color: $color-background;
  &:hover {
    background-color: $color-background !important;
    color: $color-gris-04;
  }
}
.boton--outline-negro {
  @include input-buttons;
  background-color: initial !important;
  color: $color-gris-04;
  border-color: $color-gris-04;
  border-style: solid;
  &:hover {
    background-color: $color-gris-04 !important;
    color: $color-background;
  }
}

.boton--verde {
  background-color: #25d366 !important;
  color: #073919;

  &:hover {
    background-color: #073919 !important;
    color: #fff;
  }
}

.boton--eurorepar {
  background-color: $color-eurorepar !important;
  color: #fff;

  &:hover {
    background-color: $color-eurorepar !important;
    color: #fff;
  }
}

.boton--whatsapp,
.boton--telegram {
  .bi {
    margin-right: 10px;
  }
}

.boton--whatsapp {
  background-color: #075e54 !important;
  border-color: #075e54;
  color: #25d366;
  &:hover {
    background-color: #25d366 !important;
    color: #fff;
  }
}

.boton--telegram {
  background-color: #32afed !important;
  color: #fff;
  &:hover {
    background-color: #075e54 !important;
    color: #25d366;
  }
}

.dropdown-toggle::after {
  margin-left: 0.5rem;
}
