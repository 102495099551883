@mixin max-width {
  @media screen and (min-width: 1620px) {
    max-width: 1620px;
    margin-left: auto;
    margin-right: auto;
  }
}

.marca {
  background: $color-gris-04;
  &__principal {
    @include max-width;
    margin-bottom: 2px;
    height: 800px;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 1419px) {
      height: 700px;
    }
    @media screen and (max-width: 767px) {
      height: 300px;
    }
  }
  &__galeria {
    @include max-width;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
  }
  &__local {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 10;
      object-fit: cover;
    }
  }
}

.modelos {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  &__content {
  }
  &__claim {
    width: 97% !important;
  }

  &__descripcion {
    margin-bottom: 1rem;
  }

  &__links {
    span {
      font-weight: bold;
      display: block;
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
  }
  &__actions {
    .boton {
      @media screen and (max-width: 575px) {
        display: block;
        text-align: center;
        margin-right: 0;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  &__h1 {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}

.mapa {
  iframe {
    width: 100%;
    height: 450px;
  }
}
