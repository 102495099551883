.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: $trans;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: $color-gris-04;

  @media screen and (max-width: 1419px) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  @media screen and (max-width: 767px) {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  &--home {
    background: none;
    @media screen and (max-width: 767px) {
      background-color: $color-gris-04;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      position: sticky;
      top: 0;
    }
  }

  &--sticky {
    background-color: rgba($color: #000000, $alpha: 0.8);
    box-shadow: $box-shadow;
    transition: $trans;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    @media screen and (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    .header__logo {
    }
  }

  &__burger {
    @media screen and (max-width: 1311px) {
      display: flex;
      align-items: center;
    }
    @media screen and (min-width: 1312px) {
      display: none;
    }
    @media screen and (max-width: 575px) {
      flex: 1 1 32px;
    }
    .bi {
      font-size: 2rem !important;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    @media screen and (max-width: 1311px) {
      display: none;
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1.5rem;

    img {
      width: 180px;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }

    @media screen and (max-width: 1311px) {
      display: none;
    }
    &--responsive {
      margin-left: $sp-05;
      @media screen and (min-width: 1312px) {
        display: none;
      }

      @media screen and (max-width: 1311px) {
        display: flex;
        justify-content: flex-start;
        flex: 1 1 100px;
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 575px) {
        flex-basis: 90px;
      }
      img.logo-responsive {
        width: 80px;
      }
    }
  }

  &__marcas {
    @media screen and (max-width: 575px) {
      flex: 1 1 auto;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__item {
      margin-left: 1rem;
      &--wpml {
        margin-right: 1.5rem;
        @media screen and (max-width: 1311px) {
          display: none;
        }
      }
    }
    .wpml-ls-sidebars-wpml-top {
      margin-left: 2rem !important;
    }
  }

  .bi {
    font-size: 2rem;
    color: $color-background;
    @media screen and (min-width: 576px) {
      font-size: 1.5rem;
    }
  }
}

.home .header {
  position: fixed;
}

.active-marca .header--home {
  background-color: $color-gris-04;
}

.active-castejon .menu-principal--tienda,
.active-castejon .header__right {
  display: none;
}
.active-marca .menu-principal--tiendas {
  display: none;
}

.page-template-page-empresa .header,
.page-template-page-contacto .header,
.page-template-page-cita .header,
.page-template-page-servicios .header {
  background-color: $color-principal !important;
  .menu-principal {
    .menu-item {
      a {
      }
      &:last-child {
        margin-left: 1rem;
        > a {
          background-color: $color-gris-04;
          border-radius: 8px;
          &:hover {
            color: $color-gris-04;
            background-color: $color-background;
          }
        }
      }
    }
  }
}

.wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal {
  border: 0;
  padding: 0;
  margin: 0 !important;
  li.wpml-ls-current-language {
    a {
      color: $color-principal !important;
      padding: 0px 10px 0px;
      font-weight: bold;
    }
  }

  a.wpml-ls-link {
    margin-right: 0px;
    padding-right: 0px;
    line-height: initial;
  }
}
