.widget,
.widgettitle {
  &__title {
    margin-bottom: 1.3rem;
    font-size: 1.3rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      margin-bottom: 0.6rem;
      font-size: 1rem;
    }
  }
}

.widgettitle {
  margin-bottom: 1.3rem;
  font-size: 1.3rem;
  font-weight: bold;
  @media screen and (max-width: 575px) {
    margin-bottom: 0.6rem;
    font-size: 1rem;
  }
}

.widget--footer {
  margin-bottom: 2rem;
  .product-categories,
  .menu {
    list-style: none;
    padding-left: 0rem;
    li a {
      font-size: 0.9rem;
      color: $color-gris-02;
      &:hover {
        color: $color-principal;
      }
    }
  }
}

.social-icon {
  font-size: 30px !important;
}

// .widget--page {
//   @media screen and (max-width: 575px) {
//     margin-bottom: 2rem !important;
//   }
//   .product-categories,
//   .menu {
//     list-style: none;
//     padding-left: 0rem;
//     @media screen and (max-width: 767px) {
//       display: flex;
//       flex-wrap: wrap;
//     }
//     li {
//       @media screen and (max-width: 767px) {
//         margin-right: 8px;
//         margin-bottom: 8px;
//       }
//       a {
//         @media screen and (max-width: 767px) {
//           padding: 4px 8px;
//           background-color: $color-gris-02;
//           color: $color-background;
//           display: inline-flex;
//           border-radius: 2px;
//         }
//       }
//     }
//     li.current-menu-item {
//       a {
//         background-color: $color-gris-04;
//       }
//     }
//   }
// }

// .widgettitle {
//   margin-bottom: 1rem;
//   font-size: 1.2rem;
//   @media screen and (max-width: 575px) {
//     margin-bottom: 0.3rem;
//     font-size: 1rem;
//   }
// }

.zoom-social-icons-list {
  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

// PAGE

.widget--page {
  margin-bottom: 4rem;
}

// BLOG

.widget_recent_entries,
.widget_categories {
  margin-bottom: 2.5rem;
  @media screen and (max-width: 575px) {
    margin-bottom: 1.7rem;
  }
  h4 {
    margin-bottom: 0.5rem;
  }
  ul {
    padding-left: 0px;
    list-style: none;
  }
  li {
    margin-bottom: 0.5rem;
  }
}

// WPML TOP

.wpml-ls-sidebars-wpml-top {
  margin-left: 0;
  margin-right: 1rem;
  .wpml-ls-slot-wpml-top {
    border-radius: 4px;
    .wpml-ls-link {
      padding: 0 !important;
      margin: 0px !important;
      margin-left: 10px !important;
      .wpml-ls-flag {
        padding: 2px !important;
        border: 3px solid rgba($color: #000000, $alpha: 0.1);
        box-sizing: content-box;
        border-radius: 4px;
        transition: $trans;
        &:hover {
          border-color: rgba($color: #000000, $alpha: 0.3) !important;
          transition: $trans;
        }
      }
    }
  }
  .wpml-ls-current-language {
    .wpml-ls-flag {
      border-color: rgba($color: #000000, $alpha: 0.3) !important;
      transition: $trans;
    }
  }
}
