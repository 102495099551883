.grupo {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  &__titulo {
    width: 60%;
    font-size: 5.5rem;
    text-transform: uppercase;
    line-height: 1.2;
    span {
      color: $color-principal;
    }
  }

  &__descripcion {
  }

  &__menu {
    margin-bottom: 1rem;
  }

  &__texto {
    width: 60%;
    font-size: 1.3rem;
    line-height: 1.5;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}

.somos {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__destacado {
    font-size: 1.6rem;
    line-height: 1.3;
    width: 80%;
    @media screen and (max-width: 575px) {
      width: 100%;
      font-size: 1.3rem;
    }
  }

  &__descripcion {
    font-size: $font-size-small;
  }
}

.empresa-banner {
  background: url("../assets/site/empresa.png") center center no-repeat;
  background-size: cover;
  height: 400px;
  @media screen and (max-width: 575px) {
    background-size: 180%;
    height: 312px;
  }
}
