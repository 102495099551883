.pre-footer {
  &__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
    padding-bottom: 5rem;
    border-bottom: solid 1px #979797;
    @media screen and (max-width: 1199px) {
      gap: 1rem;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 2.5rem;
    }
  }

  &__item {
  }

  &__titulo {
    color: $color-principal;
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__datos {
    display: flex;
    flex-direction: column;
    color: $color-gris-02;
    p {
      margin-bottom: 0;
    }
  }
}

.footer {
  @media screen and (max-width: 767px) {
    padding: 2rem 0 0 0;
  }
  &__container {
    margin-bottom: 4rem;
    @media screen and (max-width: 767px) {
      // display: flex;
      // justify-content: center;
      // text-align: center;
      // margin-bottom: 2rem;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;
    margin-bottom: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  &__1 {
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      grid-column: 1 / 3;
    }
    img {
      width: 200px;
      height: auto;
    }
  }
  &__2 {
    // text-align: center;
  }
  &__3 {
    // text-align: center;
  }
  &__4 {
    // text-align: center;
  }
  &__legal {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.subfooter {
  padding: 2rem 0rem;
  background-color: #383838;
  &__copy {
    color: $color-background;
    text-align: center;
    a {
      color: $color-background;
    }
  }
}

.next-gen {
  padding-bottom: $sp-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $sp-1;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $sp-1 $sp-1;
    flex-wrap: wrap;
    padding-top: $sp-1;
    border-top: 1px dashed $color-background;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: $font-size / 2;
    }
  }

  &__logos {
    display: flex;
    gap: $sp-1;
  }
  &__img {
    img {
      width: 200px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-gris-04;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
