.cita {
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  &__descripcion {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  &__whatsapp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 575px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      a {
        margin: 0 10px 10px 0;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__form {
  }

  &__form-title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}
