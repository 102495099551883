@charset "UTF-8";
:root {
  --app-height: 100%; }

body {
  background-color: white;
  font-size: 1rem;
  color: #000;
  font-weight: 400;
  font-family: urw-form, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased; }
  @media screen and (max-width: 575px) {
    body {
      font-size: 0.9rem; } }

a {
  color: #f00303;
  text-decoration: none;
  transition: 0.2s all ease-in-out; }
  a:hover {
    color: #c80101;
    transition: 0.2s all ease-in-out; }

.container--min {
  max-width: 1098px; }

.pad-b-min {
  padding-bottom: 3rem; }

.mar-b-min {
  margin-bottom: 3rem; }

.pad-b {
  padding-bottom: 6rem; }

.mar-b {
  margin-bottom: 6rem; }

.pad-b-max {
  padding-bottom: 10rem; }

.mar-b-max {
  margin-bottom: 10rem; }

.pad-t-min {
  padding-top: 3rem; }

.mar-t-min {
  margin-top: 3rem; }

.pad-t {
  padding-top: 6rem; }

.mar-t {
  margin-top: 6rem; }

.mar-r-min {
  margin-right: 0.75rem; }

.pad-t-max {
  padding-top: 10rem; }

.mar-t-max {
  margin-top: 10rem; }

.pad-min {
  padding: 3rem 0; }

.pad {
  padding: 6rem 0; }

.pad-max {
  padding: 10rem 0; }

.color-primario {
  background-color: #f00303; }

.color-blanco {
  color: white; }

.color-secundario {
  background-color: #8a7154; }

.bg-primario {
  background-color: #f00303; }

.bg-primario {
  background-color: #8a7154; }

.bg-gris-light {
  background-color: #ebebeb; }

.bg-gris {
  background-color: #ebebeb; }

.grecaptcha-badge {
  visibility: hidden; }

@media screen and (max-width: 575px) {
  .pad-b-min {
    padding-bottom: 3rem; }
  .mar-b-min {
    margin-bottom: 3rem; }
  .pad-b {
    padding-bottom: 3rem; }
  .mar-b {
    margin-bottom: 3rem; }
  .pad-b-max {
    padding-bottom: 3rem; }
  .mar-b-max {
    margin-bottom: 3rem; }
  .pad-t-min {
    padding-top: 3rem; }
  .mar-t-min {
    margin-top: 3rem; }
  .pad-t {
    padding-top: 3rem; }
  .mar-t {
    margin-top: 3rem; }
  .mar-r-min {
    margin-right: 0.75rem; }
  .pad-t-max {
    padding-top: 3rem; }
  .mar-t-max {
    margin-top: 3rem; }
  .pad-min {
    padding: 3rem 0; }
  .pad {
    padding: 3rem 0; }
  .pad-max {
    padding: 3rem 0; }
  .color-primario {
    background-color: #f00303; }
  .color-blanco {
    color: white; }
  .color-secundario {
    background-color: #8a7154; }
  .bg-primario {
    background-color: #f00303; }
  .bg-primario {
    background-color: #8a7154; }
  .bg-gris-light {
    background-color: #ebebeb; }
  .bg-gris {
    background-color: #ebebeb; }
  .grecaptcha-badge {
    visibility: hidden; } }

.color-whatsapp {
  color: #128c7e; }
  .color-whatsapp:hover {
    color: #0c5f55; }

h1,
h2,
h3,
h4,
h5 {
  line-height: initial; }

.titulo {
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .titulo {
      margin-bottom: 1.5rem; } }
  .titulo__content {
    display: flex;
    justify-content: space-between; }
  .titulo__background {
    font-size: 1rem;
    color: white;
    padding: 5px 10px;
    background-color: #f00303;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    display: inline-flex; }
    @media screen and (max-width: 575px) {
      .titulo__background {
        font-size: 0.9rem;
        letter-spacing: 1px;
        padding: 3px 8px; } }
  .titulo__header-2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3.5rem; }
  .titulo__claim {
    width: 60%;
    font-size: 5.5rem;
    text-transform: uppercase;
    line-height: 1; }
    @media screen and (max-width: 767px) {
      .titulo__claim {
        font-size: 2.5rem;
        margin-bottom: 0; } }
    @media screen and (max-width: 575px) {
      .titulo__claim {
        width: 100%; } }
    .titulo__claim span {
      color: #f00303; }
    .titulo__claim--center {
      align-self: center;
      margin-bottom: 0; }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: 0.2s all ease-in-out;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;
  background-color: black; }
  @media screen and (max-width: 1419px) {
    .header {
      padding-left: 1.2rem;
      padding-right: 1.2rem; } }
  @media screen and (max-width: 767px) {
    .header {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem; } }
  .header--home {
    background: none; }
    @media screen and (max-width: 767px) {
      .header--home {
        background-color: black;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        position: sticky;
        top: 0; } }
  .header--sticky {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.2s all ease-in-out;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem; }
    @media screen and (max-width: 767px) {
      .header--sticky {
        padding-top: 0;
        padding-bottom: 0; } }
  @media screen and (max-width: 1311px) {
    .header__burger {
      display: flex;
      align-items: center; } }
  @media screen and (min-width: 1312px) {
    .header__burger {
      display: none; } }
  @media screen and (max-width: 575px) {
    .header__burger {
      flex: 1 1 32px; } }
  .header__burger .bi {
    font-size: 2rem !important; }
  .header__nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold; }
    @media screen and (max-width: 1311px) {
      .header__nav {
        display: none; } }
  .header__logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1.5rem; }
    .header__logo img {
      width: 180px; }
      @media screen and (max-width: 575px) {
        .header__logo img {
          width: 100px; } }
    @media screen and (max-width: 1311px) {
      .header__logo {
        display: none; } }
    .header__logo--responsive {
      margin-left: 1.5rem; }
      @media screen and (min-width: 1312px) {
        .header__logo--responsive {
          display: none; } }
      @media screen and (max-width: 1311px) {
        .header__logo--responsive {
          display: flex;
          justify-content: flex-start;
          flex: 1 1 100px;
          margin-right: 0.5rem; } }
      @media screen and (max-width: 575px) {
        .header__logo--responsive {
          flex-basis: 90px; } }
      .header__logo--responsive img.logo-responsive {
        width: 80px; }
  @media screen and (max-width: 575px) {
    .header__marcas {
      flex: 1 1 auto; } }
  .header__right {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .header__right__item {
      margin-left: 1rem; }
      .header__right__item--wpml {
        margin-right: 1.5rem; }
        @media screen and (max-width: 1311px) {
          .header__right__item--wpml {
            display: none; } }
    .header__right .wpml-ls-sidebars-wpml-top {
      margin-left: 2rem !important; }
  .header .bi {
    font-size: 2rem;
    color: white; }
    @media screen and (min-width: 576px) {
      .header .bi {
        font-size: 1.5rem; } }

.home .header {
  position: fixed; }

.active-marca .header--home {
  background-color: black; }

.active-castejon .menu-principal--tienda,
.active-castejon .header__right {
  display: none; }

.active-marca .menu-principal--tiendas {
  display: none; }

.page-template-page-empresa .header,
.page-template-page-contacto .header,
.page-template-page-cita .header,
.page-template-page-servicios .header {
  background-color: #f00303 !important; }
  .page-template-page-empresa .header .menu-principal .menu-item:last-child,
  .page-template-page-contacto .header .menu-principal .menu-item:last-child,
  .page-template-page-cita .header .menu-principal .menu-item:last-child,
  .page-template-page-servicios .header .menu-principal .menu-item:last-child {
    margin-left: 1rem; }
    .page-template-page-empresa .header .menu-principal .menu-item:last-child > a,
    .page-template-page-contacto .header .menu-principal .menu-item:last-child > a,
    .page-template-page-cita .header .menu-principal .menu-item:last-child > a,
    .page-template-page-servicios .header .menu-principal .menu-item:last-child > a {
      background-color: black;
      border-radius: 8px; }
      .page-template-page-empresa .header .menu-principal .menu-item:last-child > a:hover,
      .page-template-page-contacto .header .menu-principal .menu-item:last-child > a:hover,
      .page-template-page-cita .header .menu-principal .menu-item:last-child > a:hover,
      .page-template-page-servicios .header .menu-principal .menu-item:last-child > a:hover {
        color: black;
        background-color: white; }

.wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal {
  border: 0;
  padding: 0;
  margin: 0 !important; }
  .wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal li.wpml-ls-current-language a {
    color: #f00303 !important;
    padding: 0px 10px 0px;
    font-weight: bold; }
  .wpml-ls-sidebars-wpml-top.wpml-ls-legacy-list-horizontal a.wpml-ls-link {
    margin-right: 0px;
    padding-right: 0px;
    line-height: initial; }

.pre-footer__content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3rem;
  padding-bottom: 5rem;
  border-bottom: solid 1px #979797; }
  @media screen and (max-width: 1199px) {
    .pre-footer__content {
      gap: 1rem; } }
  @media screen and (max-width: 991px) {
    .pre-footer__content {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2rem; } }
  @media screen and (max-width: 767px) {
    .pre-footer__content {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 575px) {
    .pre-footer__content {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 2.5rem; } }

.pre-footer__titulo {
  color: #f00303;
  font-weight: bold;
  font-size: 1.5rem; }

.pre-footer__datos {
  display: flex;
  flex-direction: column;
  color: #555555; }
  .pre-footer__datos p {
    margin-bottom: 0; }

@media screen and (max-width: 767px) {
  .footer {
    padding: 2rem 0 0 0; } }

.footer__container {
  margin-bottom: 4rem; }

.footer__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  width: 100%;
  margin-bottom: 3rem; }
  @media screen and (max-width: 991px) {
    .footer__grid {
      grid-template-columns: 1fr 1fr;
      gap: 1rem; } }

.footer__1 {
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    .footer__1 {
      grid-column: 1 / 3; } }
  .footer__1 img {
    width: 200px;
    height: auto; }

.footer__legal {
  text-align: center;
  display: flex;
  justify-content: center; }

.subfooter {
  padding: 2rem 0rem;
  background-color: #383838; }
  .subfooter__copy {
    color: white;
    text-align: center; }
    .subfooter__copy a {
      color: white; }

.next-gen {
  padding-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .next-gen {
      padding-bottom: 3rem; } }
  .next-gen__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem 3rem;
    flex-wrap: wrap;
    padding-top: 3rem;
    border-top: 1px dashed white; }
    @media screen and (max-width: 575px) {
      .next-gen__content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem; } }
  .next-gen__logos {
    display: flex;
    gap: 3rem; }
  .next-gen__img img {
    width: 200px;
    height: auto; }
    @media screen and (max-width: 575px) {
      .next-gen__img img {
        width: 100px; } }
  .next-gen__description {
    color: black;
    font-size: 0.9rem; }
    @media screen and (max-width: 575px) {
      .next-gen__description {
        text-align: center; } }

.menu-marcas {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  margin-bottom: 0;
  height: 100%; }
  @media screen and (max-width: 1311px) {
    .menu-marcas {
      margin-right: auto; } }
  .menu-marcas--negro img {
    filter: initial !important; }
  .menu-marcas--empresa {
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 575px) {
      .menu-marcas--empresa {
        margin-bottom: 3rem; } }
  .menu-marcas .menu-item {
    margin-right: 1rem; }
    @media screen and (max-width: 575px) {
      .menu-marcas .menu-item {
        margin-right: 0.2rem; } }
    .menu-marcas .menu-item a img {
      filter: invert(0.5);
      opacity: 0.5;
      transition: 0.2s all ease-in-out;
      width: auto;
      height: 30px; }
      @media screen and (max-width: 575px) {
        .menu-marcas .menu-item a img {
          height: 22px;
          width: auto;
          padding: 0 0.25rem; }
          .menu-marcas .menu-item a img:hover {
            filter: invert(1);
            transform: scale(1.2);
            transition: 0.2s all ease-in-out; } }
    .menu-marcas .menu-item--kia a img {
      width: 50px; }
      @media screen and (max-width: 575px) {
        .menu-marcas .menu-item--kia a img {
          width: 35px; } }
  .menu-marcas .current-menu-item img, .active-citroen .menu-marcas .menu-item--citroen img, .active-peugeot .menu-marcas .menu-item--peugeot img, .active-mercedes .menu-marcas .menu-item--mercedes img, .active-opel .menu-marcas .menu-item--opel img, .active-kia .menu-marcas .menu-item--kia img {
    filter: invert(1);
    opacity: 1; }
    .menu-marcas .current-menu-item img:hover, .active-citroen .menu-marcas .menu-item--citroen img:hover, .active-peugeot .menu-marcas .menu-item--peugeot img:hover, .active-mercedes .menu-marcas .menu-item--mercedes img:hover, .active-opel .menu-marcas .menu-item--opel img:hover, .active-kia .menu-marcas .menu-item--kia img:hover {
      transform: scale(1);
      cursor: default; }

.active-castejon .menu-marcas--negro .menu-item img {
  filter: initial !important; }

@media screen and (max-width: 767px) {
  .active-marca.active-citroen .menu-marcas .menu-item:not(.menu-item--citroen) {
    display: none; } }

@media screen and (max-width: 767px) {
  .active-marca.active-peugeot .menu-marcas .menu-item:not(.menu-item--peugeot) {
    display: none; } }

@media screen and (max-width: 767px) {
  .active-marca.active-mercedes .menu-marcas .menu-item:not(.menu-item--mercedes) {
    display: none; } }

@media screen and (max-width: 767px) {
  .active-marca.active-opel .menu-marcas .menu-item:not(.menu-item--opel) {
    display: none; } }

@media screen and (max-width: 767px) {
  .active-marca.active-kia .menu-marcas .menu-item:not(.menu-item--kia) {
    display: none; } }

.menu-principal {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin-bottom: 0; }
  .menu-principal .menu-item > a {
    padding: 10px 1rem;
    color: white;
    display: inline-block; }
  .menu-principal .menu-item:last-child {
    margin-left: 1rem; }
    .menu-principal .menu-item:last-child > a {
      background-color: #f00303;
      border-radius: 8px; }
  @media screen and (max-width: 575px) {
    .menu-principal--tiendas, .menu-principal--tienda {
      display: none; } }
  .menu-principal--tiendas .menu-item > a, .menu-principal--tienda .menu-item > a {
    font-weight: bold;
    display: inline-block;
    align-items: center; }

.active-marca .menu-principal#menu-principal .menu-item:last-child a {
  background-color: white !important;
  color: black !important; }

.active-castejon .menu-marcas .menu-item img {
  filter: invert(1);
  opacity: 1 !important;
  transition: 0.2s all ease-in-out; }
  .active-castejon .menu-marcas .menu-item img:hover {
    filter: invert(1);
    transform: scale(1.2);
    transition: 0.2s all ease-in-out; }

.active-castejon .menu-marcas .current-menu-item img, .active-castejon .active-citroen .menu-marcas .menu-item--citroen img, .active-citroen .active-castejon .menu-marcas .menu-item--citroen img, .active-castejon .active-peugeot .menu-marcas .menu-item--peugeot img, .active-peugeot .active-castejon .menu-marcas .menu-item--peugeot img, .active-castejon .active-mercedes .menu-marcas .menu-item--mercedes img, .active-mercedes .active-castejon .menu-marcas .menu-item--mercedes img, .active-castejon .active-opel .menu-marcas .menu-item--opel img, .active-opel .active-castejon .menu-marcas .menu-item--opel img, .active-castejon .active-kia .menu-marcas .menu-item--kia img, .active-kia .active-castejon .menu-marcas .menu-item--kia img {
  filter: invert(1); }
  .active-castejon .menu-marcas .current-menu-item img:hover, .active-castejon .active-citroen .menu-marcas .menu-item--citroen img:hover, .active-citroen .active-castejon .menu-marcas .menu-item--citroen img:hover, .active-castejon .active-peugeot .menu-marcas .menu-item--peugeot img:hover, .active-peugeot .active-castejon .menu-marcas .menu-item--peugeot img:hover, .active-castejon .active-mercedes .menu-marcas .menu-item--mercedes img:hover, .active-mercedes .active-castejon .menu-marcas .menu-item--mercedes img:hover, .active-castejon .active-opel .menu-marcas .menu-item--opel img:hover, .active-opel .active-castejon .menu-marcas .menu-item--opel img:hover, .active-castejon .active-kia .menu-marcas .menu-item--kia img:hover, .active-kia .active-castejon .menu-marcas .menu-item--kia img:hover {
    transform: scale(1);
    cursor: default; }

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  color: white;
  padding: 3rem;
  z-index: 1000;
  overflow-y: scroll; }
  @media screen and (max-width: 575px) {
    .m-overlay {
      padding: 1rem; } }
  .m-overlay__content {
    display: flex;
    flex-direction: column;
    height: 100%; }
    @media screen and (max-width: 575px) {
      .m-overlay__content {
        padding: 0px 12px; } }
  .m-overlay__header {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .m-overlay__logo {
    margin: 1rem 0 1rem 0; }
    .m-overlay__logo img {
      width: 250px; }
      @media screen and (max-width: 575px) {
        .m-overlay__logo img {
          width: 146px; } }
  .m-overlay__close .bi {
    font-size: 2.5rem; }
  .m-overlay__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 1rem; }
  .m-overlay__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 2rem; }
    @media screen and (max-width: 575px) {
      .m-overlay__nav {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 375px) {
      .m-overlay__nav {
        margin-bottom: 1rem; } }
    .m-overlay__nav li a {
      color: white;
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 700;
      display: inline-block;
      padding: 4px 10px; }
  .m-overlay__marcas {
    margin-bottom: 1rem; }
  .m-overlay__nav-marcas {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0; }
    .m-overlay__nav-marcas .menu-item {
      margin: 8px; }
      .m-overlay__nav-marcas .menu-item a {
        padding: 5px 1rem;
        background-color: #f00303;
        color: white;
        border-radius: 4px;
        display: inline-block;
        font-size: 1rem; }
  .m-overlay__categorias {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px dashed #555555; }
    .m-overlay__categorias .widget__title {
      text-align: center;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: 0.9rem; }
    .m-overlay__categorias ul {
      list-style: none;
      padding-left: 0px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column; }
      .m-overlay__categorias ul li a {
        font-size: 1.7rem;
        padding: 8px 5px;
        font-weight: bold; }
        @media screen and (max-width: 360px) {
          .m-overlay__categorias ul li a {
            font-size: 1.4rem; } }
  .m-overlay__actions {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .m-overlay__whatsapp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .m-overlay__whatsapp a {
      margin: 0 8px;
      padding: 5px 1rem; }
  .m-overlay .link-underline {
    color: white; }

@media screen and (max-width: 575px) {
  .m-overlay .container {
    padding: 0px; } }

@media screen and (max-width: 375px) {
  .m-overlay .container {
    padding: 0px; } }

.categorias-home {
  background-color: white;
  padding: 0.3rem 1.5rem 0rem; }
  @media screen and (min-width: 768px) {
    .categorias-home--home {
      display: none; } }
  .categorias-home--shop ul.product-categories {
    padding-top: 0 !important; }

.single-product .categorias-home {
  display: none; }

.categorias-header__title {
  display: none; }

.categorias-header ul.product-categories {
  margin-left: -1.1rem;
  margin-right: -1.1rem;
  padding-left: 1rem;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  @media screen and (min-width: 768px) {
    .categorias-header ul.product-categories {
      flex-wrap: wrap;
      margin-left: 0rem;
      margin-right: 0rem; } }
  @media screen and (max-width: 767px) {
    .categorias-header ul.product-categories {
      justify-content: flex-start; } }
  .categorias-header ul.product-categories li.current-cat a {
    background-color: #555555 !important; }
  .categorias-header ul.product-categories li.cat-item {
    margin: 0 5px; }
    @media screen and (min-width: 768px) {
      .categorias-header ul.product-categories li.cat-item {
        margin: 0 5px 5px 0; } }
    @media screen and (max-width: 767px) {
      .categorias-header ul.product-categories li.cat-item {
        margin: 0 0 0 8px; } }
    @media screen and (max-width: 767px) {
      .categorias-header ul.product-categories li.cat-item:first-child {
        margin-left: 0 !important; } }
    .categorias-header ul.product-categories li.cat-item a {
      padding: 9px 1rem 9px;
      color: white;
      background-color: black;
      border-radius: 50px;
      font-size: 0.9rem;
      transition: 0.2s all ease-in-out;
      display: inline-flex;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: inherit;
      white-space: nowrap; }
      .categorias-header ul.product-categories li.cat-item a:hover {
        transition: 0.2s all ease-in-out;
        background-color: #f00303;
        color: white !important; }
      .categorias-header ul.product-categories li.cat-item a:focus {
        transition: 0.2s all ease-in-out;
        background-color: black;
        color: white !important; }

.menu-footer__list {
  padding-left: 0;
  list-style: none; }
  .menu-footer__list .menu-item a {
    color: rgba(0, 0, 0, 0.7); }

.menu-footer-social__item {
  display: inline-flex;
  margin-right: 1rem; }
  .menu-footer-social__item img {
    width: 35px;
    height: auto; }

@media screen and (max-width: 767px) {
  .menu-footer-legal {
    width: 100%;
    display: block; } }

.menu-footer-legal__list {
  padding-left: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .menu-footer-legal__list {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .menu-footer-legal__list {
      justify-content: flex-start; } }
  .menu-footer-legal__list .menu-item {
    margin: 0 1rem; }
    @media screen and (max-width: 767px) {
      .menu-footer-legal__list .menu-item {
        margin: 0 1rem 1rem 0; } }

.widget__title,
.widgettitle__title {
  margin-bottom: 1.3rem;
  font-size: 1.3rem;
  font-weight: bold; }
  @media screen and (max-width: 575px) {
    .widget__title,
    .widgettitle__title {
      margin-bottom: 0.6rem;
      font-size: 1rem; } }

.widgettitle {
  margin-bottom: 1.3rem;
  font-size: 1.3rem;
  font-weight: bold; }
  @media screen and (max-width: 575px) {
    .widgettitle {
      margin-bottom: 0.6rem;
      font-size: 1rem; } }

.widget--footer {
  margin-bottom: 2rem; }
  .widget--footer .product-categories,
  .widget--footer .menu {
    list-style: none;
    padding-left: 0rem; }
    .widget--footer .product-categories li a,
    .widget--footer .menu li a {
      font-size: 0.9rem;
      color: #555555; }
      .widget--footer .product-categories li a:hover,
      .widget--footer .menu li a:hover {
        color: #f00303; }

.social-icon {
  font-size: 30px !important; }

@media screen and (max-width: 575px) {
  .zoom-social-icons-list {
    display: flex;
    justify-content: flex-start;
    align-items: center; } }

.widget--page {
  margin-bottom: 4rem; }

.widget_recent_entries,
.widget_categories {
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 575px) {
    .widget_recent_entries,
    .widget_categories {
      margin-bottom: 1.7rem; } }
  .widget_recent_entries h4,
  .widget_categories h4 {
    margin-bottom: 0.5rem; }
  .widget_recent_entries ul,
  .widget_categories ul {
    padding-left: 0px;
    list-style: none; }
  .widget_recent_entries li,
  .widget_categories li {
    margin-bottom: 0.5rem; }

.wpml-ls-sidebars-wpml-top {
  margin-left: 0;
  margin-right: 1rem; }
  .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top {
    border-radius: 4px; }
    .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link {
      padding: 0 !important;
      margin: 0px !important;
      margin-left: 10px !important; }
      .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link .wpml-ls-flag {
        padding: 2px !important;
        border: 3px solid rgba(0, 0, 0, 0.1);
        box-sizing: content-box;
        border-radius: 4px;
        transition: 0.2s all ease-in-out; }
        .wpml-ls-sidebars-wpml-top .wpml-ls-slot-wpml-top .wpml-ls-link .wpml-ls-flag:hover {
          border-color: rgba(0, 0, 0, 0.3) !important;
          transition: 0.2s all ease-in-out; }
  .wpml-ls-sidebars-wpml-top .wpml-ls-current-language .wpml-ls-flag {
    border-color: rgba(0, 0, 0, 0.3) !important;
    transition: 0.2s all ease-in-out; }

.page-title {
  background-color: #f00303;
  padding: 3rem 0rem; }
  @media screen and (max-width: 575px) {
    .page-title {
      padding: 1rem 0; } }
  .page-title__h {
    margin-bottom: 0rem;
    font-size: 3.5rem;
    font-weight: 500;
    color: white; }
    @media screen and (max-width: 575px) {
      .page-title__h {
        margin-bottom: 0.2rem;
        font-size: 2rem;
        line-height: 1; } }
    @media screen and (max-width: 360px) {
      .page-title__h {
        margin-bottom: 0.5rem;
        font-size: 1.8rem; } }
    .page-title__h span {
      padding-left: 1rem; }
      @media screen and (max-width: 575px) {
        .page-title__h span {
          font-size: 0.9rem; } }
  .page-title__breadcrumbs {
    color: white;
    margin-bottom: 0.5rem;
    font-size: 0.9rem; }
    @media screen and (max-width: 575px) {
      .page-title__breadcrumbs {
        font-size: 0.7rem;
        margin-bottom: 5px; } }
    .page-title__breadcrumbs a {
      font-weight: 500;
      color: white; }
      .page-title__breadcrumbs a:hover {
        color: black; }

.single-product .page-title {
  padding-top: 1.5rem;
  background: white;
  padding-bottom: 0.5rem; }
  .single-product .page-title__breadcrumbs {
    color: black !important; }
    .single-product .page-title__breadcrumbs a {
      color: rgba(0, 0, 0, 0.7); }
  @media screen and (max-width: 575px) {
    .single-product .page-title {
      padding-top: 5.5rem; } }

/* SCSS Botones
--------------------------------------------------- */
.link-underline {
  text-decoration: underline; }

.boton {
  font-size: 0.9rem;
  border-radius: 0.35rem;
  padding: 8px 20px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  font-weight: 500; }
  @media (max-width: 575px) {
    .boton {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .boton {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .boton .bi {
    margin-left: 5px; }
  .boton:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: 0.2s all ease-in-out; }
  .boton .bi {
    display: inline-flex;
    align-items: center; }

.boton--lg {
  font-size: 1rem;
  padding: 8px 30px; }

.boton--sm {
  padding: 0.55rem 1.2rem;
  letter-spacing: 0px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out; }

.boton--block {
  text-align: center;
  display: block;
  width: 100%; }

.boton--principal {
  background-color: #f00303;
  color: white;
  border-color: #f00303; }
  .boton--principal:hover {
    background-color: #c80101 !important;
    border-color: #c80101;
    color: white; }

.boton--blanco {
  background-color: white !important;
  color: #f00303 !important; }
  .boton--blanco:hover {
    background-color: white !important;
    color: black; }

.boton--outline-blanco {
  font-size: 0.9rem;
  border-radius: 0.35rem;
  padding: 8px 20px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  background-color: initial !important;
  color: white;
  border-color: white; }
  @media (max-width: 575px) {
    .boton--outline-blanco {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .boton--outline-blanco {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .boton--outline-blanco .bi {
    margin-left: 5px; }
  .boton--outline-blanco:hover {
    background-color: white !important;
    color: black; }

.boton--outline-negro {
  font-size: 0.9rem;
  border-radius: 0.35rem;
  padding: 8px 20px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  background-color: initial !important;
  color: black;
  border-color: black;
  border-style: solid; }
  @media (max-width: 575px) {
    .boton--outline-negro {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .boton--outline-negro {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .boton--outline-negro .bi {
    margin-left: 5px; }
  .boton--outline-negro:hover {
    background-color: black !important;
    color: white; }

.boton--verde {
  background-color: #25d366 !important;
  color: #073919; }
  .boton--verde:hover {
    background-color: #073919 !important;
    color: #fff; }

.boton--eurorepar {
  background-color: #ff4b00 !important;
  color: #fff; }
  .boton--eurorepar:hover {
    background-color: #ff4b00 !important;
    color: #fff; }

.boton--whatsapp .bi,
.boton--telegram .bi {
  margin-right: 10px; }

.boton--whatsapp {
  background-color: #075e54 !important;
  border-color: #075e54;
  color: #25d366; }
  .boton--whatsapp:hover {
    background-color: #25d366 !important;
    color: #fff; }

.boton--telegram {
  background-color: #32afed !important;
  color: #fff; }
  .boton--telegram:hover {
    background-color: #075e54 !important;
    color: #25d366; }

.dropdown-toggle::after {
  margin-left: 0.5rem; }

.blog-listado {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem; }
  @media screen and (max-width: 575px) {
    .blog-listado {
      grid-template-columns: 1fr;
      gap: 2.5rem; } }
  .blog-listado__thumbnail {
    margin-bottom: 0.5rem; }
    @media screen and (max-width: 575px) {
      .blog-listado__thumbnail {
        margin-bottom: 0.5rem; } }
    .blog-listado__thumbnail img {
      width: 100%;
      height: auto;
      margin-bottom: 1.2rem; }
      @media screen and (max-width: 575px) {
        .blog-listado__thumbnail img {
          margin-bottom: 0.8rem; } }
  .blog-listado__categoria {
    margin-bottom: 0.2rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__titulo {
      font-size: 1rem; } }
  .blog-listado__titulo a {
    font-weight: bold;
    font-size: 1.3rem;
    display: inline-block;
    line-height: 1.3; }

.page__entry h2,
.page__entry p,
.page__entry ul,
.page__entry ol,
.page__entry table {
  margin-bottom: 1.8rem; }
  @media screen and (max-width: 575px) {
    .page__entry h2,
    .page__entry p,
    .page__entry ul,
    .page__entry ol,
    .page__entry table {
      margin-bottom: 1.2rem; } }

.page__entry h2 {
  font-weight: bold; }

.page__entry h3 {
  color: #f00303; }

.post .wp-block-button__link {
  background-color: #f00303;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-size: 0.9rem; }

.post .wc-block-grid__product {
  padding: 1rem;
  background-color: #ebebeb; }
  @media screen and (min-width: 768px) {
    .post .wc-block-grid__product {
      margin: 0.5rem;
      border: 0;
      flex: 0 0 30% !important;
      max-width: 30% !important; } }
  .post .wc-block-grid__product__product-image {
    text-align: center; }
    @media screen and (max-width: 575px) {
      .post .wc-block-grid__product__product-image img {
        width: 80% !important; } }

.post .wc-block-grid__product-price {
  margin-bottom: 0.5rem !important; }

.post .wc-block-grid__product-link {
  text-decoration: none !important; }

.post__title {
  font-size: 3rem;
  font-weight: 600;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .post__title {
      font-size: 1.5rem; } }

.post__meta {
  margin-bottom: 1rem;
  text-align: center; }
  @media screen and (max-width: 575px) {
    .post__meta {
      margin-bottom: 1rem; } }

.post__autor {
  text-align: center;
  margin-bottom: 6rem; }
  @media screen and (max-width: 575px) {
    .post__autor {
      margin-bottom: 3rem; } }

.post__entry {
  margin-bottom: 6rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9); }
  .post__entry h2 {
    font-size: 2.3rem;
    margin: 2.5rem 0rem 1rem 0;
    color: #f00303; }
  .post__entry h3 {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 2.5rem 0rem 1rem 0;
    text-decoration: underline; }
  .post__entry h4 {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.3;
    margin: 2.5rem 0rem 1rem 0; }
  .post__entry figure {
    margin: 2rem 0; }
  .post__entry figcaption {
    font-size: 0.7rem; }
  .post__entry img {
    width: 100%;
    height: auto; }
  .post__entry p,
  .post__entry ul,
  .post__entry ol {
    margin-bottom: 1.8rem;
    line-height: 1.7;
    font-size: 1rem; }
  .post__entry blockquote.wp-block-quote {
    text-align: center !important;
    padding: 1.3rem; }
    @media screen and (max-width: 575px) {
      .post__entry blockquote.wp-block-quote {
        font-size: 1.1rem;
        margin-left: 0rem; } }
    .post__entry blockquote.wp-block-quote p {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 1.2rem;
      color: #c80101;
      text-align: center; }
    .post__entry blockquote.wp-block-quote cite {
      font-size: 0.9rem;
      font-weight: 400;
      margin-bottom: 0.2 !important;
      font-style: normal;
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0.1);
      padding: 1px 9px;
      border-radius: 3px; }
      @media screen and (max-width: 575px) {
        .post__entry blockquote.wp-block-quote cite {
          padding: 0;
          background-color: initial; } }
  .post__entry .wp-block-pullquote {
    background-color: rgba(244, 244, 228, 0.9) !important;
    border-width: 2px 0;
    border-color: rgba(202, 202, 176, 0.9);
    border-style: solid; }
    .post__entry .wp-block-pullquote blockquote cite {
      font-size: 0.9rem;
      font-weight: 400;
      font-style: normal;
      font-weight: bold; }
  .post__entry a {
    color: #f00303;
    text-decoration: underline; }

.autor {
  display: inline-flex; }
  .autor__avatar {
    margin-right: 1rem; }
    .autor__avatar img {
      border-radius: 50px;
      width: 60px;
      height: auto; }
  .autor__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start; }
  .autor__name {
    font-weight: bold; }

.blog-lista {
  background-color: #ebebeb;
  padding: 4rem 0; }
  @media screen and (max-width: 575px) {
    .blog-lista {
      padding: 2rem 0; } }

.blog-listado__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__grid {
      grid-template-columns: repeat(2, 1fr); } }
  .blog-listado__grid img {
    width: 100%;
    height: auto; }

.blog-listado__titular {
  margin-bottom: 2rem;
  font-size: 2rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__titular {
      margin-bottom: 1rem;
      font-size: 1rem; } }

.blog-listado a {
  display: inline-block; }

.blog-listado__thumb-link {
  margin-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__thumb-link {
      font-size: 0.7rem; } }

.blog-listado__category {
  font-size: 0.9rem; }

.blog-listado__titulo {
  font-size: 1.1rem; }
  @media screen and (max-width: 575px) {
    .blog-listado__titulo {
      font-size: 0.9rem; } }

@media screen and (max-width: 575px) {
  .blog-listado__lectura {
    font-size: 0.7rem; } }

.wpcf7 {
  margin: 1.5rem 0; }
  .wpcf7 .form-group {
    margin-bottom: 1rem; }
  .wpcf7 .form-control {
    width: 100%; }
  .wpcf7 .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red; }

.wpcf7-acceptance {
  font-size: 0.9rem;
  color: black;
  color: rgba(0, 0, 0, 0.4); }
  .wpcf7-acceptance .wpcf7-list-item {
    margin-left: 0; }

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px; }

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: white; }

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: white; }

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500; }

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important; }

.gdpr-text p {
  font-size: 0.6rem; }

.cta--rojo {
  background-color: #f00303; }

.cta__content {
  display: grid;
  grid-template-columns: 6fr 3fr;
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .cta__content {
      gap: 1rem;
      grid-template-columns: 1fr; } }

.cta__claim {
  font-size: 3.75rem;
  text-transform: uppercase;
  line-height: 1.2; }
  @media screen and (max-width: 991px) {
    .cta__claim {
      font-size: 2rem; } }
  .cta__claim span {
    color: #f00303; }
  .cta__claim--rojo span {
    color: white; }

.cta__action {
  width: 60%;
  margin-left: auto; }
  @media screen and (max-width: 767px) {
    .cta__action {
      width: 100%; } }
  .cta__action a {
    display: inline-block; }

.cta__descripcion {
  margin-bottom: 2rem; }
  .cta__descripcion--rojo {
    color: white; }

.page-informacion-legal .table tr td:first-child {
  width: 30%; }

.cli-bar-container {
  justify-content: center !important;
  text-align: center; }

.cli-bar-message {
  width: auto !important; }

.hero {
  background-color: #000;
  height: 100vh;
  position: relative; }
  @media screen and (max-width: 575px) {
    .hero {
      height: 50vh; } }
  @media screen and (min-width: 991px) and (min-height: 991px) {
    .hero {
      height: 991px; } }
  .hero__bg {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden; }
    .hero__bg video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden; }
  .hero__link {
    display: inline-block;
    position: absolute;
    bottom: 3rem;
    z-index: 10;
    transition: 0.2s all ease-in-out; }
    .hero__link:hover {
      transform: translateY(1rem);
      transition: 0.2s all ease-in-out; }
  .hero__claim {
    position: relative;
    color: #f00303;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .hero__claim {
        justify-content: flex-end;
        padding-bottom: 2rem; } }
  .hero__pretitulo {
    font-size: 1rem;
    color: white;
    padding: 5px 10px;
    background-color: #f00303;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px; }
    @media screen and (max-width: 767px) {
      .hero__pretitulo {
        font-size: 0.8rem;
        padding: 3px 7px; } }
  .hero__titulo {
    font-size: 5.5rem;
    color: white;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .hero__titulo {
        font-size: 2.5rem;
        line-height: 1.2; } }
  .hero__descripcion {
    display: inline-block;
    width: 30%;
    font-size: 1rem;
    color: white; }
    @media screen and (max-width: 767px) {
      .hero__descripcion {
        width: 100%;
        font-size: 1.2rem;
        display: none; } }
  .hero__arrow {
    width: 50px;
    height: auto; }
    @media screen and (max-width: 767px) {
      .hero__arrow {
        display: none; } }

.claim {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .claim {
      grid-template-columns: 1fr;
      gap: 1rem; } }
  .claim__titulo {
    width: 97% !important; }
  .claim__content {
    display: flex;
    align-items: flex-end;
    flex-direction: column; }
    @media screen and (max-width: 767px) {
      .claim__content {
        display: block; } }
  .claim__descripcion {
    text-align: right;
    width: 60%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 767px) {
      .claim__descripcion {
        width: 100%;
        text-align: left; } }

.home-euro {
  padding: 2rem 0;
  background-color: #ebebeb;
  border-bottom: 1px dashed #555555; }
  .home-euro__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .home-euro__container {
        gap: 1rem; } }
  .home-euro__logo {
    width: 250px;
    height: auto; }
  .home-euro__content h3 {
    margin: 0;
    font-size: 1.4rem; }

.grid-ofertas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem; }
  @media screen and (max-width: 991px) {
    .grid-ofertas {
      grid-template-columns: 1fr;
      gap: 1.5rem; } }
  .grid-ofertas__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .grid-ofertas__item {
        grid-template-columns: 1fr;
        border: 1px solid rgba(0, 0, 0, 0.2); } }
  .grid-ofertas__picture img {
    width: 100%;
    height: auto; }
  .grid-ofertas__content {
    padding: 1.5rem; }
  .grid-ofertas__titulo h3 {
    margin-top: 0;
    font-size: 1.3rem;
    font-weight: bold;
    color: #f00303; }

.ocasion ul {
  margin-bottom: 6rem; }

.destacados {
  padding: 2rem 0 3rem 0; }
  @media screen and (max-width: 575px) {
    .destacados {
      padding: 1rem 0 1rem 0; } }
  @media screen and (max-width: 575px) {
    .destacados ul.products {
      margin-top: 0rem; } }

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0; }

.loop-coches {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem; }
  .loop-coches img {
    width: 100%;
    height: auto; }
  .loop-coches__item {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem 1rem 2rem 2rem;
    overflow: hidden;
    border-radius: 0.5rem; }
  .loop-coches__titulo {
    margin-bottom: 0px; }
    .loop-coches__titulo a {
      color: black;
      font-weight: bold;
      font-size: 1.5rem; }
      .loop-coches__titulo a:hover {
        color: #f00303; }
  .loop-coches__datos, .loop-coches__gas {
    font-size: 1.1rem;
    color: #555555; }
  .loop-coches__precio {
    font-size: 2rem;
    color: #f00303;
    font-weight: bold; }

.hero-marquee {
  overflow: hidden;
  white-space: nowrap;
  font-size: 5.9375rem;
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6; }
  .hero-marquee--last {
    border-top: none; }

.marqueeRight,
.marquee {
  font-size: 0; }

.clipped-text-right,
.clipped-text {
  display: inline-block;
  font-size: 3.5rem;
  text-transform: uppercase;
  padding-right: 1rem;
  line-height: 1.2; }

.marqueeRight {
  margin-top: 2px; }
  .marqueeRight .clipped-text-right {
    color: #f00303; }

.nosotros__descripcion {
  font-size: 1.5rem;
  width: 60%;
  margin-bottom: 8rem; }
  @media screen and (max-width: 991px) {
    .nosotros__descripcion {
      width: 100%;
      font-size: 1.2rem;
      margin-bottom: 2rem; } }

.empresa {
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 8rem; }
  @media screen and (max-width: 991px) {
    .empresa {
      display: block;
      padding-left: 1rem;
      padding-right: 1rem; } }
  .empresa img {
    width: 100%;
    height: auto; }
  .empresa__col-02 {
    margin-bottom: 2rem; }
  .empresa__descripcion {
    width: 60%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 991px) {
      .empresa__descripcion {
        width: 100%; } }
  .empresa__img {
    width: 300px !important;
    height: auto !important;
    margin-bottom: 2rem; }
    @media screen and (max-width: 991px) {
      .empresa__img {
        max-width: 100%;
        width: 100% !important;
        margin-top: 1rem; } }

.marcas__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .marcas__content {
      flex-wrap: wrap;
      justify-content: space-evenly; } }

.marcas__item img {
  width: 45px;
  height: auto; }
  @media screen and (max-width: 767px) {
    .marcas__item img {
      width: 25px; } }

.marcas__item--kia img {
  width: auto;
  height: 20px; }
  @media screen and (max-width: 767px) {
    .marcas__item--kia img {
      width: 40px;
      height: auto; } }

.clientes {
  background-color: #f00303; }
  .clientes__content {
    display: grid;
    grid-template-columns: 8fr auto;
    gap: 27rem; }
    @media screen and (max-width: 991px) {
      .clientes__content {
        grid-template-columns: 1fr;
        gap: 2rem; } }
  .clientes__descripcion {
    font-size: 1.5rem;
    color: white; }
  .clientes__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }
    .clientes__links span {
      display: inline-block;
      width: 50px; }
  .clientes__item {
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #fff; }
    .clientes__item img {
      width: 25px;
      height: auto; }
  .clientes__link {
    color: white;
    font-weight: 500;
    font-size: 1.2rem; }

.home ul.products.columns-8 li.product {
  width: 15%;
  margin-right: 1rem; }
  @media screen and (max-width: 575px) {
    .home ul.products.columns-8 li.product {
      width: 80%;
      margin-right: 1rem; } }

@media screen and (max-width: 575px) {
  .slider-desk {
    display: none; } }

@media screen and (min-width: 576px) {
  .slider-mobile {
    display: none; } }

.manifest {
  padding-top: 2rem; }
  .manifest__copy {
    font-size: 1.8rem;
    font-weight: bold;
    width: 60%; }
    @media screen and (max-width: 767px) {
      .manifest__copy {
        font-size: 1.5rem;
        width: 100%;
        line-height: 1.8; } }
    .manifest__copy mark {
      background-color: #f00303;
      color: white; }

.categories {
  padding: 2rem 0 4rem 0; }
  @media screen and (max-width: 575px) {
    .categories {
      padding: 1rem 0 2rem 0; } }
  .categories ul.products img {
    width: 150px;
    height: 150px; }
  .categories .count {
    display: none; }

.compres {
  padding: 2rem 0; }
  .compres__content {
    display: flex;
    justify-content: space-around; }
    @media screen and (max-width: 575px) {
      .compres__content {
        flex-direction: column; } }
  .compres__item {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .compres__item {
        margin-bottom: 1rem; } }
    .compres__item:last-child {
      margin-bottom: 0; }
  .compres__icono {
    margin-right: 1.5rem; }
    @media screen and (max-width: 575px) {
      .compres__icono {
        flex: 0 0 10%;
        display: flex;
        align-items: center;
        justify-content: center; } }
    .compres__icono .bi {
      font-size: 2rem; }
  .compres__titulo {
    font-weight: 500;
    font-size: 0.9rem; }
    .compres__titulo span {
      display: block;
      color: #555555; }

.quote {
  text-align: center;
  background: url("../assets/site/img-montseny-width.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  color: white;
  position: relative; }
  .quote__overlay {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    top: 0;
    bottom: 0; }
  .quote__container {
    padding: 12rem 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .quote__container {
        padding: 5rem 0; } }
  .quote__frase {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    width: 70%;
    position: relative;
    z-index: 3; }
    @media screen and (max-width: 575px) {
      .quote__frase {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        width: 90%; } }
  .quote__autor {
    position: relative;
    z-index: 3; }

.tipos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem; }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .tipos {
      gap: 1rem; } }
  @media screen and (max-width: 991px) {
    .tipos {
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem; } }
  @media screen and (max-width: 767px) {
    .tipos {
      gap: 2rem; } }
  @media screen and (max-width: 575px) {
    .tipos {
      grid-template-columns: repeat(6, 1fr);
      gap: 2rem;
      overflow-y: scroll; } }
  .tipos__link:hover {
    transition: 0.2s all ease-in-out; }
    .tipos__link:hover .tipos__img {
      background-color: rgba(0, 0, 0, 0.06);
      transition: 0.2s all ease-in-out; }
  .tipos__title {
    font-size: 1rem;
    color: black; }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .tipos__title {
        font-size: 0.9rem; } }
    @media screen and (max-width: 575px) {
      .tipos__title {
        font-size: 1rem; } }
  .tipos__img {
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.04);
    margin-bottom: 1rem;
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 575px) {
      .tipos__img {
        width: 230px;
        height: auto; } }
  .tipos__description {
    font-size: 0.7rem;
    color: #555555; }

.g-contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem; }
  @media screen and (max-width: 767px) {
    .g-contacto {
      grid-template-columns: 1fr; } }
  .g-contacto__contacto {
    margin-top: 1.1rem; }
  .g-contacto__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 1rem;
    margin-bottom: 3rem; }
  .g-contacto__titulo {
    text-transform: uppercase;
    display: block;
    color: black;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 1.2rem; }

.contactos {
  list-style: none;
  padding-left: 0rem;
  margin-left: 0rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .contactos {
      margin-left: 1rem;
      padding-left: 1rem; } }
  @media (max-width: 575px) {
    .contactos {
      padding-left: 0;
      margin-left: 0;
      border: 0; } }
  .contactos li {
    margin-bottom: 0.3rem; }

.nosaltres {
  padding: 1rem 0 2rem 0; }
  .nosaltres__content {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 5rem; }
  .nosaltres__img {
    margin-bottom: 2rem; }
    .nosaltres__img img {
      width: 100%;
      height: auto; }

.lookbook {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  margin-bottom: 100px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }
  .lookbook__item {
    display: flex;
    align-items: center; }
  .lookbook__img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03); }

.marca {
  background: black; }
  .marca__principal {
    margin-bottom: 2px;
    height: 800px;
    background-size: cover;
    background-position: center center; }
    @media screen and (min-width: 1620px) {
      .marca__principal {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (max-width: 1419px) {
      .marca__principal {
        height: 700px; } }
    @media screen and (max-width: 767px) {
      .marca__principal {
        height: 300px; } }
  .marca__galeria {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px; }
    @media screen and (min-width: 1620px) {
      .marca__galeria {
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto; } }
  .marca__local img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 10;
    object-fit: cover; }

.modelos {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .modelos {
      grid-template-columns: 1fr;
      gap: 1.5rem; } }
  .modelos__claim {
    width: 97% !important; }
  .modelos__descripcion {
    margin-bottom: 1rem; }
  .modelos__links span {
    font-weight: bold;
    display: block;
    margin-bottom: 1rem;
    font-size: 1.2rem; }
  @media screen and (max-width: 575px) {
    .modelos__actions .boton {
      display: block;
      text-align: center;
      margin-right: 0; }
      .modelos__actions .boton:first-child {
        margin-bottom: 10px; } }
  .modelos__h1 {
    font-size: 1.5rem;
    text-transform: uppercase; }

.mapa iframe {
  width: 100%;
  height: 450px; }

.grupo__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media screen and (max-width: 575px) {
    .grupo__content {
      grid-template-columns: 1fr;
      gap: 1rem; } }

.grupo__titulo {
  width: 60%;
  font-size: 5.5rem;
  text-transform: uppercase;
  line-height: 1.2; }
  .grupo__titulo span {
    color: #f00303; }

.grupo__menu {
  margin-bottom: 1rem; }

.grupo__texto {
  width: 60%;
  font-size: 1.3rem;
  line-height: 1.5; }
  @media screen and (max-width: 575px) {
    .grupo__texto {
      width: 100%; } }

.somos__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media screen and (max-width: 575px) {
    .somos__content {
      grid-template-columns: 1fr; } }

.somos__destacado {
  font-size: 1.6rem;
  line-height: 1.3;
  width: 80%; }
  @media screen and (max-width: 575px) {
    .somos__destacado {
      width: 100%;
      font-size: 1.3rem; } }

.somos__descripcion {
  font-size: 0.9rem; }

.empresa-banner {
  background: url("../assets/site/empresa.png") center center no-repeat;
  background-size: cover;
  height: 400px; }
  @media screen and (max-width: 575px) {
    .empresa-banner {
      background-size: 180%;
      height: 312px; } }

.servicios__content {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 3rem; }
  @media screen and (max-width: 575px) {
    .servicios__content {
      grid-template-columns: 1fr;
      gap: 1rem; } }

.servicios__claim {
  width: 97% !important; }

.servicios__descripcion {
  width: 60%;
  font-size: 1.3rem;
  line-height: 1.5; }
  @media screen and (max-width: 575px) {
    .servicios__descripcion {
      width: 100%; } }

.listado__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem; }
  @media screen and (max-width: 575px) {
    .listado__content {
      grid-template-columns: 1fr; } }

.listado__item {
  display: flex;
  align-items: flex-start; }

.listado__icono {
  margin-right: 2.5rem;
  margin-top: 0.5rem; }
  .listado__icono img {
    width: 65px;
    height: auto; }
  @media screen and (max-width: 575px) {
    .listado__icono {
      margin-right: 1.5rem;
      margin-top: 0.5rem; }
      .listado__icono img {
        width: 56px; } }

.listado__titulo {
  font-size: 1.1rem;
  color: #c80101;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 1rem; }

.cita__content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5rem; }
  @media screen and (max-width: 575px) {
    .cita__content {
      grid-template-columns: 1fr; } }

.cita__descripcion {
  font-size: 1.2rem;
  margin-bottom: 1.5rem; }

.cita__whatsapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  @media screen and (max-width: 575px) {
    .cita__whatsapp {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; }
      .cita__whatsapp a {
        margin: 0 10px 10px 0; } }

.cita__info {
  display: flex;
  flex-direction: column; }

.cita__form-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 2rem; }

.er-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .er-grid {
      grid-template-columns: 1fr; } }

.er-servicios__item {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 3rem; }

.er-servicios__pic {
  flex: 0 0 60px; }
  .er-servicios__pic img {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
    filter: grayscale(1); }

.er-servicios__title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #ff4b00; }

.erc {
  margin-bottom: 4rem; }
  .erc__titulo {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.6; }
    @media screen and (max-width: 767px) {
      .erc__titulo--max {
        font-weight: 400;
        font-size: 1.3rem; } }
  .erc__titulo-corto {
    font-size: 1.3rem;
    color: #ff4b00; }
    @media screen and (max-width: 767px) {
      .erc__titulo-corto {
        font-weight: 400;
        font-size: 1.1rem;
        font-weight: bold; } }
  .erc__logo {
    margin-bottom: 3rem; }
    @media screen and (max-width: 767px) {
      .erc__logo {
        margin-bottom: 1.5rem; } }
    .erc__logo img {
      width: 50%;
      height: auto; }
      @media screen and (max-width: 767px) {
        .erc__logo img {
          width: 80%; } }
  .erc__content img.size-full {
    margin: 1.5rem 0;
    max-width: 70%;
    height: auto; }
    @media screen and (max-width: 767px) {
      .erc__content img.size-full {
        max-width: 90%; } }

#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: #f00303;
  color: white;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  font-size: 0.9rem;
  border-radius: 0.35rem;
  padding: 8px 20px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  display: inline-flex !important; }
  @media (max-width: 575px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  #submit_bizum_payment_form .bi,
  #submit_redsys_payment_form .bi {
    margin-left: 5px; }

.button.cancel {
  background-color: #555555;
  color: rgba(0, 0, 0, 0.7); }

.submit,
.button {
  font-size: 0.9rem;
  background-color: #f00303;
  color: white;
  display: inline;
  box-shadow: none;
  font-size: 0.9rem;
  border-radius: 0.35rem;
  padding: 8px 20px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  font-weight: 500; }
  @media (max-width: 575px) {
    .submit,
    .button {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .submit,
    .button {
      padding: 0.7rem 1rem;
      font-size: 0.8rem; } }
  .submit .bi,
  .button .bi {
    margin-left: 5px; }
  .submit:hover,
  .button:hover {
    background-color: #c80101;
    color: #fff; }

.single_add_to_cart_button {
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0; }
  @media (max-width: 575px) {
    .single_add_to_cart_button {
      font-size: 0.9rem !important;
      padding: .8rem 1.0rem !important; } }
  @media (max-width: 575px) {
    .single_add_to_cart_button::before {
      font-size: 1.0rem;
      margin-right: .6rem; } }
  .single_add_to_cart_button:hover {
    background-color: #c80101 !important; }

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important; }

@media screen and (max-width: 357px) {
  form.cart {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; } }

.single_add_to_cart_button.disabled {
  background-color: #b3b3b3;
  cursor: not-allowed; }
  .single_add_to_cart_button.disabled:hover {
    background-color: #b3b3b3 !important; }

.checkout-button,
.single_add_to_cart_button {
  background-color: #f00303; }
  .checkout-button:hover,
  .single_add_to_cart_button:hover {
    background-color: #c80101;
    color: white; }

.button[aria-disabled="false"] {
  border: 0; }

.button[aria-disabled="true"] {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  border: 0;
  cursor: not-allowed; }

.page-carrito .woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0; }
  @media (max-width: 991px) {
    .page-carrito .woocommerce {
      display: block; } }
  .page-carrito .woocommerce .woocommerce-notices-wrapper {
    grid-column: 1 / 3; }

.cart_totals,
.shop_table {
  width: 100%; }

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem; }
  .woocommerce-cart-form .shop_table {
    width: 100%;
    box-shadow: none !important; }
  .woocommerce-cart-form .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 767px) {
      .woocommerce-cart-form .product-remove::before {
        display: none; } }
  .woocommerce-cart-form .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important; }
  .woocommerce-cart-form .product-thumbnail {
    display: flex;
    align-items: center;
    height: 100%; }
  .woocommerce-cart-form .product-thumbnail a {
    display: flex; }
  .woocommerce-cart-form .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .woocommerce-cart-form .product-name {
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem !important; }
    .woocommerce-cart-form .product-name .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px;
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 0px;
      font-family: urw-form, sans-serif; }
      @media screen and (max-width: 767px) {
        .woocommerce-cart-form .product-name .variation {
          grid-template-columns: 3fr 1fr; } }
      .woocommerce-cart-form .product-name .variation dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px; }
      .woocommerce-cart-form .product-name .variation dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px; }
        .woocommerce-cart-form .product-name .variation dd p {
          margin: 0;
          display: inline-flex; }
  .woocommerce-cart-form .product-price {
    text-align: right; }
  .woocommerce-cart-form .product-quantity {
    text-align: right; }
    .woocommerce-cart-form .product-quantity .qty {
      padding: 9px 2px; }
  .woocommerce-cart-form .product-subtotal {
    font-weight: bold;
    text-align: right; }
    .woocommerce-cart-form .product-subtotal .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block; }
  .woocommerce-cart-form thead tr th {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.8rem !important;
    text-transform: uppercase; }

/* Tabla del carrito de la compra -------------------------- */
@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table thead {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody tr {
    display: flex;
    flex-direction: column; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table tbody td.product-thumbnail {
    display: none; } }

@media (max-width: 767px) {
  .woocommerce-cart-form table.shop_table .cart_item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 2px;
    margin-bottom: 10px; } }

.woocommerce-cart-form table.shop_table .cart_item td {
  border-bottom: 2px solid white;
  background-color: rgba(0, 0, 0, 0.03); }

.woocommerce-cart-form table.shop_table td {
  padding: 20px 9px;
  vertical-align: middle;
  line-height: 1.5em; }
  @media screen and (max-width: 575px) {
    .woocommerce-cart-form table.shop_table td {
      padding: 5px 9px; } }

.woocommerce-cart-form table.shop_table td a {
  color: #c80101; }

.woocommerce-cart-form table.shop_table th {
  font-weight: 700;
  padding: 7px 9px;
  line-height: 1.5em; }

.woocommerce-cart-form table.shop_table .actions {
  padding: 1rem 0 0 0; }
  @media (max-width: 575px) {
    .woocommerce-cart-form table.shop_table .actions::before {
      display: none; } }

.coupon {
  width: 100%;
  margin-bottom: 0.5rem; }
  .coupon label {
    display: none; }
  @media (max-width: 767px) {
    .coupon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .coupon input {
      margin-right: 10px;
      width: 50%; } }
  .coupon button {
    padding: 12px 15px; }

/* Atributos en tabla para responsive --------------- */
@media (max-width: 767px) {
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left; } }

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px; }

.woocommerce table.shop_table .screen-reader-text {
  display: none; }

.update_cart {
  float: right; }

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right; }

/* CARRITO TOTALES Y TRANSPORTE ------------------- */
.cart_totals {
  display: block; }
  @media (max-width: 767px) {
    .cart_totals {
      width: 100%;
      font-size: 0.9rem !important; } }
  .cart_totals h2 {
    font-size: 1.5rem !important; }
  .cart_totals .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px; }
  .cart_totals tbody tr td,
  .cart_totals tbody tr th {
    padding: 12px 12px !important; }
  .cart_totals tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05); }
    .cart_totals tbody tr td::before {
      display: none; }
  .cart_totals tbody tr th {
    font-size: 0.7rem;
    text-align: right;
    width: 25%; }
    @media (max-width: 767px) {
      .cart_totals tbody tr th {
        width: 25%; } }
  .cart_totals tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05); }
  .cart_totals h2 {
    margin-top: 0; }
  .cart_totals .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important; }
  .woocommerce-shipping-methods li label {
    font-weight: bold; }
  .woocommerce-shipping-methods li label span {
    font-weight: normal; }
  .woocommerce-shipping-methods li .betrs_option_desc {
    font-size: 0.7rem; }

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right; }

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left; }

.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem; }

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem; }
  @media (max-width: 767px) {
    .col2-set {
      grid-template-columns: 1fr;
      grid-gap: 2rem; } }
  .col2-set .col-1 {
    margin-bottom: 2rem; }
  .col2-set .col-2,
  .col2-set .col-1 {
    width: 100%; }

#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields h3 label span {
  font-size: 1.0rem !important;
  margin-top: 0;
  color: #c80101 !important;
  font-weight: 500;
  margin-bottom: 2rem; }

.woocommerce-address-fields label,
.woocommerce-checkout label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-address-fields .woocommerce-input-wrapper,
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-address-fields input,
.woocommerce-address-fields textarea,
.woocommerce-checkout input,
.woocommerce-checkout textarea {
  display: block;
  width: 100%; }

.form-row-wide {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  #billing_phone_field,
  #billing_postcode_field,
  .form-row-first {
    width: 48%;
    display: inline-block;
    margin-right: 4%; } }

@media (min-width: 992px) {
  #billing_email_field,
  #billing_city_field,
  .form-row-last {
    width: 48%;
    display: inline-block; } }

/* checkout ---------------------------------- */
@media (min-width: 992px) {
  form.woocommerce-checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #customer_details {
    grid-column: 1 / 2;
    grid-row: 1 / 3; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review_heading {
    grid-column: 2 / 3;
    grid-row: 1 / 2; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review {
    grid-column: 2 / 3;
    grid-row: 2 / 3; } }

@media (min-width: 992px) {
  #customer_details.col2-set {
    display: flex;
    flex-direction: column;
    gap: 0; } }

/* checkout ---------------------------------- */
.woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text {
  display: inline;
  font-size: 1.1rem; }

.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
  display: inline; }

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  background-color: #ebebeb;
  padding: 1.4rem !important;
  margin-bottom: 3rem; }

.woocommerce-table--order-details thead tr th,
.woocommerce-checkout-review-order-table thead tr th {
  text-align: left;
  padding: 9px 12px;
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details thead tr th,
    .woocommerce-checkout-review-order-table thead tr th {
      padding: 5px 8px; } }

.woocommerce-table--order-details tfoot tr th,
.woocommerce-table--order-details tfoot tr td,
.woocommerce-table--order-details tbody tr td,
.woocommerce-checkout-review-order-table tfoot tr th,
.woocommerce-checkout-review-order-table tfoot tr td,
.woocommerce-checkout-review-order-table tbody tr td {
  padding: 9px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 0.9rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details tfoot tr th,
    .woocommerce-table--order-details tfoot tr td,
    .woocommerce-table--order-details tbody tr td,
    .woocommerce-checkout-review-order-table tfoot tr th,
    .woocommerce-checkout-review-order-table tfoot tr td,
    .woocommerce-checkout-review-order-table tbody tr td {
      padding: 5px 8px !important; } }

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem; }
  .woocommerce-checkout-review-order-table thead .product-total,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1.1rem; }

.woocommerce-checkout-payment {
  background-color: #ebebeb;
  border: 1px solid #555555;
  padding: 2rem;
  border-radius: 5px; }
  @media (max-width: 767px) {
    .woocommerce-checkout-payment {
      padding: 1rem; } }
  .woocommerce-checkout-payment ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
      padding-right: 1rem;
      padding-bottom: .5rem; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
          padding-bottom: .7rem;
          padding-right: 0; } }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label {
      font-weight: 700;
      display: inline; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label .about_paypal {
      font-weight: 400; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method.payment_method_bizum img {
      width: 80px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img {
      max-width: 35px !important;
      height: auto; }
      .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method img:first-child {
        margin-left: 10px !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods .input-radio {
      display: inline-block;
      width: auto;
      margin-right: .5rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      padding: .5rem 1.0rem;
      font-size: 0.9rem;
      border-radius: 0.5rem;
      background-color: white;
      border: 1px solid #d2d2d2; }
      @media screen and (max-width: 575px) {
        .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
          margin-left: 0;
          margin-right: 0; } }
      .woocommerce-checkout-payment ul.wc_payment_methods .payment_box p {
        margin-bottom: 5px; }
    .woocommerce-checkout-payment ul.wc_payment_methods label {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.8rem; }

@media screen and (max-width: 575px) {
  .payment_method_stripe {
    margin: 0rem !important;
    padding: 0rem !important; } }

.payment_method_stripe label[for="payment_method_stripe"] {
  width: 90% !important; }

.place-order button.button {
  background-color: #f00303 !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem; }
  @media (max-width: 767px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }
  @media (min-width: 991px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text {
  font-size: .8rem; }
  .woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text p {
    margin-bottom: 5px; }

.woocommerce-terms-and-conditions-wrapper a {
  color: #c80101; }

.woocommerce-terms-and-conditions-checkbox-text {
  font-size: 1rem; }

/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
h2.woocommerce-order-details__title {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2.0rem !important;
  color: #c80101;
  font-weight: bold;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .cart-empty,
    .woocommerce-thankyou-order-received,
    h2.wc-bacs-bank-details-heading,
    h2.woocommerce-order-details__title {
      font-size: 1.3rem !important; } }

.wc-bacs-bank-details-account-name {
  margin-top: 0; }

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important; }

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: white;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem; }
  @media (max-width: 767px) {
    .order_details,
    .wc-bacs-bank-details,
    .woocommerce-order-overview {
      flex-direction: column;
      padding: 1rem; } }
  .order_details li,
  .wc-bacs-bank-details li,
  .woocommerce-order-overview li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid rgba(0, 0, 0, 0.7); }
    @media (max-width: 767px) {
      .order_details li,
      .wc-bacs-bank-details li,
      .woocommerce-order-overview li {
        border-right: 0; } }
  @media (max-width: 767px) {
    .order_details li:last-child,
    .wc-bacs-bank-details li:last-child,
    .woocommerce-order-overview li:last-child {
      margin-bottom: 0 !important; } }
  .order_details li strong,
  .wc-bacs-bank-details li strong,
  .woocommerce-order-overview li strong {
    display: block; }

/* Order recibed ------------------------- */
.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial; }

#nxshop_spot_selection {
  background-color: #f00303;
  color: #fff; }
  @media (max-width: 767px) {
    #nxshop_spot_selection {
      display: block;
      text-align: center; } }

#nxshop_table_container {
  font-size: 0.85rem;
  margin-top: 1rem; }
  #nxshop_table_container table tbody tr td {
    vertical-align: middle !important; }
    #nxshop_table_container table tbody tr td p {
      margin-bottom: 0; }

.woocommerce-account-fields .create-account {
  padding: 1rem;
  border: 1px dashed rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  color: #f00303; }

.woocommerce-form-login-toggle {
  margin-bottom: 2rem; }

.page-template-page-cart .woocommerce-form-login {
  margin-bottom: 2rem; }
  .page-template-page-cart .woocommerce-form-login > p {
    font-size: 0.7rem; }
  .page-template-page-cart .woocommerce-form-login input {
    display: block; }
  .page-template-page-cart .woocommerce-form-login .form-row {
    display: block;
    width: 100%; }
  .page-template-page-cart .woocommerce-form-login .woocommerce-form__label-for-checkbox {
    display: flex; }

@font-face {
  font-family: 'WooCommerce';
  src: url("../fonts/WooCommerce.eot?jj1bj4");
  src: url("../fonts/WooCommerce.eot?jj1bj4#iefix") format("embedded-opentype"), url("../fonts/WooCommerce.ttf?jj1bj4") format("truetype"), url("../fonts/WooCommerce.woff?jj1bj4") format("woff"), url("../fonts/WooCommerce.svg?jj1bj4#WooCommerce") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="wcicon-"], [class*=" wcicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WooCommerce' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wcicon-storefront:before {
  content: "\e900"; }

.wcicon-ccv:before {
  content: "\e604"; }

.wcicon-virtual:before {
  content: "\e000"; }

.wcicon-up-down:before {
  content: "\e022"; }

.wcicon-reports:before {
  content: "\e023"; }

.wcicon-refresh:before {
  content: "\e031"; }

.wcicon-navigation:before {
  content: "\e032"; }

.wcicon-status-fill:before {
  content: "\e03c"; }

.wcicon-contract:before {
  content: "\e004"; }

.wcicon-downloadable:before {
  content: "\e001"; }

.wcicon-plus:before {
  content: "\e007"; }

.wcicon-simple:before {
  content: "\e006"; }

.wcicon-on-hold:before {
  content: "\e033"; }

.wcicon-external:before {
  content: "\e034"; }

.wcicon-contract-2:before {
  content: "\e036"; }

.wcicon-expand-2:before {
  content: "\e035"; }

.wcicon-phone:before {
  content: "\e037"; }

.wcicon-user:before {
  content: "\e038"; }

.wcicon-status:before {
  content: "\e039"; }

.wcicon-status-pending:before {
  content: "\e012"; }

.wcicon-status-cancelled:before {
  content: "\e013"; }

.wcicon-west:before {
  content: "\e02f"; }

.wcicon-south:before {
  content: "\e02e"; }

.wcicon-mail:before {
  content: "\e02d"; }

.wcicon-inventory:before {
  content: "\e02c"; }

.wcicon-attributes:before {
  content: "\e02b"; }

.wcicon-north:before {
  content: "\e02a"; }

.wcicon-east:before {
  content: "\e029"; }

.wcicon-note:before {
  content: "\e028"; }

.wcicon-windows:before {
  content: "\e027"; }

.wcicon-user2:before {
  content: "\e026"; }

.wcicon-search-2:before {
  content: "\e025"; }

.wcicon-search:before {
  content: "\e024"; }

.wcicon-star-empty:before {
  content: "\e021"; }

.wcicon-share:before {
  content: "\e030"; }

.wcicon-phone-fill:before {
  content: "\e03b"; }

.wcicon-woo:before {
  content: "\e03d"; }

.wcicon-user-fill:before {
  content: "\e03a"; }

.wcicon-grouped:before {
  content: "\e002"; }

.wcicon-status-refunded:before {
  content: "\e014"; }

.wcicon-status-completed:before {
  content: "\e015"; }

.wcicon-variable:before {
  content: "\e003"; }

.wcicon-expand:before {
  content: "\e005"; }

.wcicon-status-failed:before {
  content: "\e016"; }

.wcicon-check:before {
  content: "\e017"; }

.wcicon-right:before {
  content: "\e008"; }

.wcicon-up:before {
  content: "\e009"; }

.wcicon-query:before {
  content: "\e018"; }

.wcicon-down:before {
  content: "\e00a"; }

.wcicon-truck-1:before {
  content: "\e019"; }

.wcicon-left:before {
  content: "\e00b"; }

.wcicon-truck-2:before {
  content: "\e01a"; }

.wcicon-image:before {
  content: "\e00c"; }

.wcicon-globe:before {
  content: "\e01b"; }

.wcicon-link:before {
  content: "\e00d"; }

.wcicon-gear:before {
  content: "\e01c"; }

.wcicon-calendar:before {
  content: "\e00e"; }

.wcicon-cart:before {
  content: "\e01d"; }

.wcicon-processing:before {
  content: "\e00f"; }

.wcicon-card:before {
  content: "\e01e"; }

.wcicon-view:before {
  content: "\e010"; }

.wcicon-stats:before {
  content: "\e01f"; }

.wcicon-status-processing:before {
  content: "\e011"; }

.wcicon-star-full:before {
  content: "\e020"; }

.wcicon-coupon:before {
  content: "\e600"; }

.wcicon-limit:before {
  content: "\e601"; }

.wcicon-restricted:before {
  content: "\e602"; }

.wcicon-edit:before {
  content: "\e603"; }

textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7); }
  @media (max-width: 575px) {
    textarea,
    select,
    input,
    .qty {
      font-size: 0.7rem;
      padding: 11px 11px; } }

.qty {
  text-align: center;
  width: 80px; }

.shop_table .qty {
  width: 70px; }

select {
  background: #fff url("../assets/ico/ico-arrow-down-pic.svg") no-repeat right center;
  background-size: 18px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 10px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: 0.2s all ease-in-out;
  padding-right: 2rem !important;
  font-size: 0.9rem; }
  select.orderby {
    padding: 8px 10px;
    box-shadow: none; }
  select:hover {
    color: #c80101;
    transition: 0.2s all ease-in-out; }

.selection .select2-selection {
  height: 50px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15); }

.selection .select2-selection__rendered {
  padding: 10px 14px; }

.selection .select2-selection__arrow {
  padding: 10px 12px;
  margin-top: 12px;
  margin-right: 5px; }

.selection .select2-selection__clear {
  margin-right: 1rem; }

.selection .select2-selection__arrow b {
  border-width: 10px 10px 0 10px; }

.qib-container .minus {
  border-radius: 4px 0 0 4px !important; }

.qib-container .plus {
  border-radius: 0 4px 4px 0 !important; }

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem; }
  .page-woocommerce--product {
    padding-top: 0; }
  .page-woocommerce__content {
    display: grid;
    grid-template-columns: 20% 80%; }
    @media screen and (max-width: 991px) {
      .page-woocommerce__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start; } }
  @media screen and (max-width: 991px) {
    .page-woocommerce__sidebar .widget__title {
      display: none; } }

ul.product-categories {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  @media screen and (max-width: 991px) {
    ul.product-categories {
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 1.5rem; } }
  ul.product-categories li.cat-item {
    margin-bottom: 0.9rem;
    background-color: #f00303;
    padding: 4px 10px;
    border-radius: 3px;
    font-size: 1rem; }
    @media screen and (max-width: 991px) {
      ul.product-categories li.cat-item {
        margin-right: 0.9rem;
        margin-bottom: 0.5rem; } }
    ul.product-categories li.cat-item a {
      color: white; }
    ul.product-categories li.cat-item:nth-last-child(1) {
      margin-bottom: 0; }
      @media screen and (max-width: 991px) {
        ul.product-categories li.cat-item:nth-last-child(1) {
          margin-right: 0; } }

.single-product .page-woocommerce {
  padding-top: 0; }

.term-description {
  margin-top: 2.5rem; }

.widget-tallas {
  margin-bottom: 2rem; }
  .widget-tallas__widget {
    width: 100%; }
    @media screen and (min-width: 992px) {
      .widget-tallas__widget {
        width: 30%; } }

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem; }
  ul.products li.product {
    background-color: white;
    padding: 0rem;
    overflow: hidden;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 767px) {
      ul.products li.product {
        background-color: initial;
        box-shadow: none;
        border-radius: initial; } }
    ul.products li.product .button {
      background: black;
      border-color: black;
      border-width: 2px !important;
      color: white;
      border-style: solid;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: 0.2s all ease-in-out; }
      ul.products li.product .button:hover .bi {
        transition: 0.2s all ease-in-out;
        margin-right: 15px; }
      ul.products li.product .button .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: 0.2s all ease-in-out; }
  ul.products .product__cat {
    margin-bottom: 0.33333rem;
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.75rem;
    letter-spacing: 0.5px; }
    @media (max-width: 575px) {
      ul.products .product__cat {
        margin-bottom: 0.2rem !important; } }
  ul.products .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    position: relative; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__link {
        align-items: flex-start; } }
    ul.products .woocommerce-loop-product__link .onsale {
      position: absolute;
      background-color: #f00303;
      color: white;
      padding: 3px 8px 4px;
      border-radius: 50px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      top: 0.5rem;
      left: 0.5rem; }
      @media screen and (max-width: 575px) {
        ul.products .woocommerce-loop-product__link .onsale {
          font-size: 0.7rem;
          padding: 2px 8px 3px; } }
  ul.products .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    border: 1px solid #555555;
    margin-bottom: 1rem;
    border-radius: 4px; }
  ul.products .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 575px) {
      ul.products .attachment-woocommerce_thumbnail {
        margin-bottom: 0.5rem !important; } }
  ul.products .woocommerce-loop-product__title {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    display: block;
    color: black;
    font-weight: bold;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__title {
        font-size: 0.9rem;
        padding: 0; } }
  ul.products .price {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (max-width: 575px) {
      ul.products .price {
        padding: 0;
        width: 100%; } }
    ul.products .price .woocommerce-Price-amount {
      color: rgba(0, 0, 0, 0.7);
      font-size: 1rem; }
    ul.products .price .woocommerce-Price-currencySymbol {
      font-size: 0.66667rem; }
    ul.products .price ins {
      text-decoration: none !important; }
      ul.products .price ins bdi {
        color: red;
        font-size: 1.1rem; }

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem 1rem; } }
  @media (max-width: 575px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem 1rem; } }

@media (max-width: 575px) {
  .page-template-front-page .columns-4,
  .term-zona-outlet .columns-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem; } }

.columns-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.products li.product-category img {
  width: 100%;
  height: auto;
  border: 0px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem; }

.products li.product-category h2.woocommerce-loop-category__title {
  font-size: 1rem;
  color: #555555;
  text-align: center; }

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem; }

.woocommerce-result-count {
  display: inline-block;
  background-color: #555555;
  color: white;
  padding: 1px 10px;
  font-size: 0.9rem;
  border-radius: 3px; }
  @media (max-width: 575px) {
    .woocommerce-result-count {
      font-size: 0.9rem;
      display: none; } }

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both; }
  @media (max-width: 991px) {
    .woocommerce-ordering {
      float: none; } }

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid #f00303;
    padding: 2px;
    border-radius: 3px; }
  .woocommerce-pagination li {
    display: inline-block; }
  .woocommerce-pagination li .page-numbers {
    padding: 10px 20px;
    background-color: #f00303;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem; }
  .woocommerce-pagination li .page-numbers.current {
    background-color: #c80101; }
  .woocommerce-pagination li .page-numbers:hover {
    background-color: #c80101; }

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none; }

.woocommerce-form-login {
  max-width: 400px;
  margin: 0 auto; }

.woocommerce-form-row label,
.woocommerce-form-login label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem; }

.woocommerce-form-row input,
.woocommerce-form-login input {
  display: block;
  width: 100%; }

.woocommerce-form-row__rememberme,
.woocommerce-form-login__rememberme {
  padding-bottom: 1rem !important; }

.woocommerce-form-row__submit,
.woocommerce-form-login__submit {
  display: block;
  width: 100%; }

.woocommerce-form-row .lost_password,
.woocommerce-form-login .lost_password {
  font-size: 0.7rem;
  text-align: right; }

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 991px) {
    .page-template-page-login.logged-in .woocommerce {
      display: initial; } }

.woocommerce-MyAccount-navigation ul {
  list-style: none; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap; } }

.woocommerce-MyAccount-navigation li {
  margin-right: 1rem; }
  @media screen and (max-width: 991px) {
    .woocommerce-MyAccount-navigation li {
      margin-bottom: 3px; } }

@media (max-width: 991px) {
  .woocommerce-MyAccount-navigation li a {
    padding: 5px 1rem;
    background-color: #555555;
    border-radius: 50px;
    color: white;
    display: inline-flex; } }

@media (max-width: 767px) {
  .woocommerce-MyAccount-navigation li a {
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.7);
    color: white; } }

.woocommerce-MyAccount-navigation li.is-active a {
  color: rgba(0, 0, 0, 0.7); }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation li.is-active a {
      font-size: 0.7rem;
      color: white;
      background-color: #fda11e; } }

.woocommerce-MyAccount-content .woocommerce-table--order-details {
  display: block; }

.woocommerce-MyAccount-content .woocommerce-Message .button {
  display: inline-block; }

.woocommerce-EditAccountForm .woocommerce-form-row input {
  width: auto; }

.woocommerce-EditAccountForm span em {
  font-size: 0.7rem; }

.account-orders-table {
  font-size: .9rem;
  border-collapse: collapse; }
  @media (max-width: 767px) {
    .account-orders-table thead {
      display: none; } }
  @media (max-width: 767px) {
    .account-orders-table tbody {
      font-size: 0.7rem; } }
  @media (max-width: 767px) {
    .account-orders-table tbody tr {
      display: block;
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.5rem; } }
  .account-orders-table tbody td {
    text-align: center;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0; }
    @media (max-width: 767px) {
      .account-orders-table tbody td {
        text-align: right;
        padding: 8px 5px; } }
  .account-orders-table tbody .view {
    padding: 6px 1rem;
    display: block; }
  .account-orders-table .woocommerce-orders-table__cell-order-status {
    font-weight: bold; }
  .account-orders-table .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block; }

div[data-title="Cancelado"] {
  color: #760e0e; }

.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-notices-wrapper {
      margin-bottom: 1rem; } }
  .woocommerce-notices-wrapper:empty {
    margin-bottom: 0;
    padding: 0; }
  .woocommerce-notices-wrapper .woocommerce-error li {
    color: red; }
  .woocommerce-notices-wrapper .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    overflow: hidden; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message {
        display: block;
        font-size: 0.8rem;
        text-align: left;
        background-color: #faffd0;
        border: 1px solid #b5bb84;
        border-radius: 0;
        padding: 1.0rem;
        box-shadow: none;
        text-align: center; } }
    .woocommerce-notices-wrapper .woocommerce-message a.button {
      text-align: center; }
      @media (max-width: 575px) {
        .woocommerce-notices-wrapper .woocommerce-message a.button {
          float: left;
          display: inline-block;
          padding: .85rem 1rem;
          width: 100%;
          height: auto;
          margin-bottom: 0.5rem; } }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message a.restore-item {
        height: auto;
        display: inline; } }
  .woocommerce-notices-wrapper .button {
    display: inline-block;
    background-color: #c80101;
    margin-right: 2rem;
    font-weight: normal; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .button {
        width: 230px;
        margin-right: 1rem; } }
    .woocommerce-notices-wrapper .button:hover {
      color: white; }

.woocommerce-NoticeGroup-checkout .woocommerce-error li {
  color: #880f0f; }

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 2.5rem;
  row-gap: 5rem;
  position: relative; }
  @media (max-width: 767px) {
    div.product {
      display: block; } }
  div.product .woocommerce-product-gallery {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
    position: relative; }
  div.product .summary {
    grid-column: 7 / 11;
    grid-row: 1 / 2; }
    @media screen and (max-width: 991px) {
      div.product .summary {
        grid-column: 6 / 11; } }
  div.product .woocommerce-tabs {
    grid-column: 1 / 7;
    grid-row: 2 / 3; }
  div.product .related {
    grid-column: 7 / 11;
    grid-row: 2 / 3; }
  div.product .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4; }

@media (max-width: 767px) {
  .summary {
    margin-bottom: 3rem !important; } }

@media screen and (max-width: 575px) {
  .summary {
    margin-bottom: 3rem; } }

.summary .product_title {
  margin-top: 0;
  padding-top: 0;
  color: black;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 0rem;
  color: black; }
  @media (max-width: 575px) {
    .summary .product_title {
      font-size: 1.4rem !important;
      margin-bottom: 0.3rem; } }

.summary .price {
  font-size: 1.5rem !important; }
  @media (max-width: 575px) {
    .summary .price {
      font-size: 1.2rem !important; } }

.summary ins {
  text-decoration: none !important; }
  .summary ins bdi {
    color: red; }

.summary del {
  margin-right: 0.5rem; }
  .summary del bdi {
    font-size: 80%; }

.summary .screen-reader-text {
  display: none; }

.summary .cart.variations_form {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem; }

.summary .cart {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem; }

.summary .cart .qty {
  padding: 15px 10px;
  font-size: 1rem;
  text-align: center;
  box-shadow: none; }
  @media (max-width: 575px) {
    .summary .cart .qty {
      padding: 10px 5px;
      width: 80px; } }

.summary .quantity {
  margin-right: 1rem; }
  .summary .quantity.hidden {
    display: none; }

.summary .single_add_to_cart_button {
  display: flex;
  justify-content: center;
  width: 60%; }

.summary .woocommerce-variation-add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.wc-deposits-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap; }
  .wc-deposits-wrapper .wc-deposits-option {
    flex: 0 1 100%; }
  .wc-deposits-wrapper .wc-deposits-payment-description {
    background-color: green;
    padding: 1px 5px;
    border-radius: 3px;
    color: white !important; }
    .wc-deposits-wrapper .wc-deposits-payment-description .wc-deposits-amount {
      font-weight: bold; }

.ncdgw_textarea_wrapper {
  margin-top: 0.5rem; }

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  display: none; }
  @media screen and (max-width: 767px) {
    .product_meta {
      display: none; } }
  .product_meta > span {
    display: block; }
  .product_meta a {
    color: black;
    text-decoration: underline; }

.summary .price {
  margin: 0 0 1rem;
  font-weight: normal;
  color: black;
  font-size: 2rem; }
  @media (max-width: 575px) {
    .summary .price {
      border: none;
      margin: 0 0 1rem;
      padding: 0; } }
  @media (max-width: 390px) {
    .summary .price {
      margin: 0 0 0.5rem; } }

.summary .precio-financiado {
  background-color: #f00303;
  color: white;
  padding: 1rem;
  border-radius: 0.35rem;
  margin-bottom: 1.5rem; }
  .summary .precio-financiado h4 {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase; }

.summary .woocommerce-product-details__short-description {
  margin-bottom: 1.4rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7); }

.summary table.variations label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 1px;
  color: black; }

.summary table.variations .reset_variations {
  font-size: 12px;
  color: red; }

.vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap {
  padding: 2px !important;
  border-radius: 0 !important;
  width: 70px !important;
  height: 70px !important; }

.guia-tallas {
  margin-bottom: 10px; }
  .guia-tallas__link {
    cursor: pointer; }
    @media screen and (max-width: 575px) {
      .guia-tallas__link {
        font-size: 1rem; } }

.cinta {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0rem 0rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .cinta__ico {
    margin-right: 2rem; }
    .cinta__ico img {
      width: 70px;
      height: auto; }
      @media screen and (max-width: 767px) {
        .cinta__ico img {
          width: 90px; } }
  .cinta__content {
    padding-right: 1rem; }

.webtomizer_wcdp_single_deposit_form {
  flex: 1 1 100%;
  width: 100%; }
  .webtomizer_wcdp_single_deposit_form .deposit-option {
    margin-bottom: 0.5rem;
    display: block !important; }
    .webtomizer_wcdp_single_deposit_form .deposit-option .amount {
      color: #c80101 !important; }
    .webtomizer_wcdp_single_deposit_form .deposit-option .deposit-suffix {
      font-weight: bold !important; }
  .webtomizer_wcdp_single_deposit_form .switch-candy {
    box-shadow: none !important;
    text-shadow: none !important; }

.wc-deposits-options-form div a.wc-deposits-switcher {
  background: #c80101 !important; }

.coleccion {
  margin-bottom: 2rem; }
  .coleccion__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px; }
  .coleccion__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px; }
  .coleccion__quantity label {
    display: none; }
  .coleccion__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.5rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.7);
    min-width: 50px; }
  .coleccion__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: #555555;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px; }
  .coleccion__label label {
    display: flex;
    margin-bottom: 3px; }
  .coleccion__label label a {
    font-size: 13px;
    font-weight: 400;
    color: black; }
  .coleccion__price {
    text-align: right;
    font-size: 1rem;
    color: #f00303;
    font-weight: 700; }
    .coleccion__price .stock {
      margin: 0;
      font-size: 0.9rem;
      color: rgba(0, 0, 0, 0.7); }

.garantia-extras {
  font-size: 0.9rem; }
  .garantia-extras a {
    color: black;
    border-bottom: 1px solid;
    font-weight: normal; }
  .garantia-extras__nav {
    list-style: none;
    padding-left: 0; }
  .garantia-extras__item {
    margin-bottom: 8px; }
    @media (max-width: 575px) {
      .garantia-extras__item {
        margin-bottom: 6px; } }
    .garantia-extras__item .bi {
      margin-right: 5px; }
    .garantia-extras__item--share {
      padding-bottom: 8px; }
      .garantia-extras__item--share a {
        border-bottom: 0;
        font-weight: bold;
        color: #128c7e; }
  .garantia-extras a.verde-whatsapp {
    color: #128c7e; }
    @media (max-width: 575px) {
      .garantia-extras a.verde-whatsapp {
        background-color: #128c7e;
        color: white;
        display: inline-block;
        border-radius: 50px;
        padding: 0 8px;
        text-decoration: none; } }

@media (max-width: 767px) {
  .woocommerce-tabs {
    margin-bottom: 6rem; } }

@media screen and (max-width: 575px) {
  .woocommerce-tabs {
    background-color: #ebebeb;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -11px;
    margin-right: -11px; } }

.woocommerce-tabs .wc-tab h2 {
  margin-top: 0;
  font-size: 1.5rem; }
  @media (max-width: 767px) {
    .woocommerce-tabs .wc-tab h2 {
      display: none; } }

.woocommerce-tabs .wc-tab {
  padding-top: 1rem;
  border-radius: 0.5rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .wc-tab {
      padding-top: 1rem; } }

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .tabs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3rem; } }
  @media screen and (min-width: 576px) {
    .woocommerce-tabs .tabs li {
      margin-right: 0.3rem; } }
  .woocommerce-tabs .tabs li a {
    display: block;
    position: relative;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.2);
    color: black;
    border-radius: 4px; }
    @media screen and (max-width: 575px) {
      .woocommerce-tabs .tabs li a {
        text-align: center; } }
    @media screen and (max-width: 380px) {
      .woocommerce-tabs .tabs li a {
        padding: 4px 6px !important;
        font-size: 0.75rem; } }
    .woocommerce-tabs .tabs li a:hover {
      background-color: #555555;
      color: white; }
  .woocommerce-tabs .tabs li.active a {
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    position: relative; }

.comment-form-email label,
.comment-form-author label {
  display: block; }

.wp-comment-cookies-consent label {
  font-size: 0.7rem !important; }

.woocommerce-product-attributes {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-attributes-item {
      font-size: 0.8rem; } }
  .woocommerce-product-attributes-item__label {
    background-color: #ebebeb;
    width: 25%;
    text-align: right;
    padding-right: 1rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__label {
        padding-right: 0;
        text-align: center; } }
  .woocommerce-product-attributes-item__value {
    padding-left: 1rem;
    background-color: rgba(0, 0, 0, 0.05); }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__value {
        padding-left: 0;
        text-align: center; } }

.datos {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 3rem; }
  .datos__item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px; }
    .datos__item span {
      display: block;
      font-weight: bold; }

.woocommerce-product-gallery {
  position: relative;
  margin-bottom: 1rem; }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  @media screen and (min-width: 768px) {
    .woocommerce-product-gallery__wrapper {
      position: sticky;
      top: 75px; } }
  .woocommerce-product-gallery img {
    width: 100%;
    height: auto; }

.woocommerce-product-gallery__wrapper {
  margin: 0; }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image {
    width: auto;
    display: inline-flex;
    margin: 2.5px 5px 2.5px 0; }
    .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
      width: 120px;
      height: auto;
      border-radius: 4px; }
      @media (max-width: 767px) {
        .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
          width: 70px; } }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:first-child {
    width: 100%;
    display: block; }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image--placeholder {
    width: 100%;
    height: auto; }
  .woocommerce-product-gallery__wrapper .wp-post-image {
    width: 100% !important;
    height: auto;
    border-radius: 5px;
    border: 1px solid #555555; }

.products.related > h2,
.products.upsells > h2 {
  margin-bottom: 1.2rem !important;
  font-size: 1.2rem; }

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  section.related ul.products li.product {
    display: block;
    width: 100%;
    height: auto;
    clear: both; }
  section.related ul.products .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem; }
  section.related ul.products .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    height: auto;
    overflow: hidden; }
  section.related ul.products .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto; }

.garantia {
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem; }
  .garantia__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    flex: 1 0 30%; }
  .garantia__ico {
    margin-right: 10px; }
    .garantia__ico svg {
      width: 25px;
      height: 25px; }
    .garantia__ico .bi {
      font-size: 2rem;
      color: rgba(0, 0, 0, 0.7); }

#review_form .comment-reply-title {
  font-size: 0.7rem;
  margin-bottom: 1rem;
  display: block; }

.product .star-rating span:before,
.star-rating::before {
  color: #f00303 !important;
  border-color: #f00303 !important; }

.woocommerce-product-rating .woocommerce-review-link {
  font-size: 0.7rem;
  color: black; }

/**
	 * Star ratings
	 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: "WooCommerce"; }
  .star-rating::before {
    content: "\e020\e020\e020\e020\e020";
    float: left;
    top: 0;
    left: 0;
    position: absolute; }
  .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em; }
  .star-rating span::before {
    content: "\e021\e021\e021\e021\e021";
    top: 0;
    position: absolute;
    left: 0; }

.woocommerce-product-rating {
  line-height: 2;
  display: block; }
  .woocommerce-product-rating .star-rating {
    margin: 0.5em 4px 0 0;
    float: left; }

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none; }

.hreview-aggregate .star-rating {
  margin: 10px 0 0; }

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }
  #review_form #respond p {
    margin: 0 0 10px; }
  #review_form #respond .form-submit input {
    left: auto; }
  #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%; }

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none; }
  p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: "WooCommerce";
    content: "\e021";
    text-indent: 0; }
  p.stars a:hover ~ a::before {
    content: "\e021"; }

p.stars:hover a::before {
  content: "\e020"; }

p.stars.selected a.active::before {
  content: "\e020"; }

p.stars.selected a.active ~ a::before {
  content: "\e021"; }

p.stars.selected a:not(.active)::before {
  content: "\e020"; }

.commentlist {
  list-style: none;
  padding-left: 0; }
  .commentlist .comment_container {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    margin-bottom: 5px !important; }
    .commentlist .comment_container .avatar {
      width: 40px;
      height: 40px;
      display: none; }
    .commentlist .comment_container .comment-text {
      width: 100%; }
      .commentlist .comment_container .comment-text .star-rating {
        float: none; }
      .commentlist .comment_container .comment-text .meta {
        margin-top: .3rem;
        font-size: 1rem;
        margin-bottom: .3rem !important; }
      .commentlist .comment_container .comment-text .description p {
        margin-top: 0; }
