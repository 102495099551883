.menu-marcas {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  margin-bottom: 0;
  height: 100%;
  @media screen and (max-width: 1311px) {
    margin-right: auto;
  }
  &--negro {
    img {
      filter: initial !important;
    }
  }

  &--empresa {
    margin-bottom: 1.5rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 3rem;
    }
  }

  .menu-item {
    margin-right: 1rem;
    @media screen and (max-width: 575px) {
      margin-right: 0.2rem;
    }
    a {
      img {
        filter: invert(0.5);
        opacity: 0.5;
        transition: $trans;
        width: auto;
        height: 30px;

        @media screen and (max-width: 575px) {
          height: 22px;
          width: auto;
          padding: 0 0.25rem;
          &:hover {
            filter: invert(1);
            transform: scale(1.2);
            transition: $trans;
          }
        }
      }
    }
    &--kia {
      a {
        img {
          width: 50px;
          // width: auto;
          @media screen and (max-width: 575px) {
            width: 35px;
          }
        }
      }
    }
  }
  .current-menu-item {
    img {
      filter: invert(1);
      opacity: 1;
      &:hover {
        transform: scale(1);
        cursor: default;
      }
    }
  }
}

.active-castejon .menu-marcas--negro .menu-item img {
  filter: initial !important;
}

$marcas: citroen, peugeot, mercedes, opel, kia;
@each $marca in $marcas {
  .active-#{$marca} {
    .menu-marcas {
      .menu-item--#{$marca} {
        @extend .current-menu-item;
      }
    }
  }
}

@each $marca in $marcas {
  .active-marca.active-#{$marca} {
    .menu-marcas .menu-item:not(.menu-item--#{$marca}) {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}

.menu-principal {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  .menu-item {
    > a {
      padding: 10px 1rem;
      color: $color-background;
      display: inline-block;
    }
    &:last-child {
      margin-left: 1rem;
      > a {
        background-color: $color-principal;
        border-radius: 8px;
      }
    }
  }
  &--tiendas,
  &--tienda {
    @media screen and (max-width: 575px) {
      display: none;
    }
    .menu-item {
      > a {
        font-weight: bold;
        display: inline-block;
        align-items: center;
      }
    }
  }
}

.active-marca {
  .menu-principal#menu-principal {
    .menu-item {
      &:last-child {
        a {
          background-color: $color-background !important;
          color: $color-gris-04 !important;
        }
      }
    }
  }
}

// .active-marca {
//   .menu-marcas {
//     @media screen and (max-width: 767px) {
//       // display: none;
//     }
//   }
// }

.active-castejon .menu-marcas {
  .menu-item {
    img {
      //filter: invert(1) !important;
      filter: invert(1);
      opacity: 1 !important;
      transition: $trans;
      &:hover {
        filter: invert(1);
        transform: scale(1.2);
        transition: $trans;
      }
    }
  }
  .current-menu-item {
    img {
      filter: invert(1);
      &:hover {
        transform: scale(1);
        cursor: default;
      }
    }
  }
}

// Menú overlay ------------------------
.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-gris-04;
  color: $color-background;
  padding: 3rem;
  z-index: 1000;
  overflow-y: scroll;
  // overscroll-behavior: contain;
  @media screen and (max-width: 575px) {
    padding: 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 575px) {
      padding: 0px 12px;
    }
    @media screen and (max-width: 375px) {
    }
  }
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__logo {
    margin: 1rem 0 1rem 0;
    img {
      width: 250px;
      @media screen and (max-width: 575px) {
        width: 146px;
      }
    }
  }
  &__close {
    .bi {
      font-size: 2.5rem;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 2rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 375px) {
      margin-bottom: 1rem;
    }

    li {
      a {
        color: $color-background;
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: 700;
        display: inline-block;
        padding: 4px 10px;
      }
    }
  }
  &__marcas {
    margin-bottom: 1rem;
  }
  &__nav-marcas {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    .menu-item {
      margin: 8px;
      a {
        padding: 5px 1rem;
        background-color: $color-principal;
        color: $color-background;
        border-radius: 4px;
        display: inline-block;
        font-size: 1rem;
      }
    }
  }
  &__categorias {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px dashed $color-gris-02;
    .widget__title {
      text-align: center;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: $font-size-small;
    }
    ul {
      list-style: none;
      padding-left: 0px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      li {
        a {
          // color: $color-background;
          font-size: 1.7rem;
          padding: 8px 5px;
          font-weight: bold;
          &:hover {
          }
          @media screen and (max-width: 360px) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    // margin-bottom: 5rem;
    // padding-bottom: 5rem;
    align-items: center;
  }

  &__whatsapp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      margin: 0 8px;
      padding: 5px 1rem;
    }
  }
  .link-underline {
    color: $color-background;
  }
}

.m-overlay .container {
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
  @media screen and (max-width: 375px) {
    padding: 0px;
  }
}

.categorias-home {
  background-color: $color-background;
  padding: 0.3rem 1.5rem 0rem;
  &--home {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  &--shop {
    ul.product-categories {
      padding-top: 0 !important;
    }
  }
}

.single-product .categorias-home {
  display: none;
}
.categorias-header {
  &__title {
    display: none;
  }
  ul.product-categories {
    margin-left: -1.1rem;
    margin-right: -1.1rem;
    padding-left: 1rem;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      margin-left: 0rem;
      margin-right: 0rem;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
    li.current-cat {
      a {
        background-color: $color-gris-02 !important;
      }
    }
    li.cat-item {
      margin: 0 5px;
      @media screen and (min-width: 768px) {
        margin: 0 5px 5px 0;
      }
      @media screen and (max-width: 767px) {
        margin: 0 0 0 8px;
      }
      &:first-child {
        @media screen and (max-width: 767px) {
          margin-left: 0 !important;
        }
      }
      a {
        padding: 9px 1rem 9px;
        color: $color-background;
        background-color: $color-gris-04;
        border-radius: 50px;
        font-size: $font-size-small;
        transition: $trans;
        display: inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: inherit;
        white-space: nowrap;
        &:hover {
          transition: $trans;
          background-color: $color-principal;
          color: $color-background !important;
        }
        &:focus {
          transition: $trans;
          background-color: $color-gris-04;
          color: $color-background !important;
        }
      }
    }
  }
}

// Menú para el footer

.menu-footer {
  &__list {
    padding-left: 0;
    list-style: none;
    .menu-item {
      a {
        color: $color-gris-03;
      }
    }
  }
}

.menu-footer-social {
  &__item {
    display: inline-flex;
    margin-right: 1rem;
    img {
      width: 35px;
      height: auto;
    }
  }
}

.menu-footer-legal {
  @media screen and (max-width: 767px) {
    width: 100%;
    display: block;
  }
  &__list {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
    .menu-item {
      margin: 0 1rem;
      @media screen and (max-width: 767px) {
        margin: 0 1rem 1rem 0;
      }
    }
  }
}
