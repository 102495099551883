.er-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__servicios {
  }

  &__descripcion {
  }
}

.er-servicios {
  &__item {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 3rem;
  }

  &__pic {
    flex: 0 0 60px;
    img {
      width: 100%;
      height: auto;
      margin-top: 0.5rem;
      filter: grayscale(1);
    }
  }

  &__content {
  }

  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    color: $color-eurorepar;
  }

  &__descripcion {
  }
}

.erc {
  margin-bottom: 4rem;
  &__titulo {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.6;
    &--max {
      @media screen and (max-width: 767px) {
        font-weight: 400;
        font-size: 1.3rem;
      }
    }
  }
  &__titulo-corto {
    font-size: 1.3rem;
    color: $color-eurorepar;
    @media screen and (max-width: 767px) {
      font-weight: 400;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  &__logo {
    margin-bottom: 3rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
    img {
      width: 50%;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 80%;
      }
    }
  }
  &__content {
    img.size-full {
      margin: 1.5rem 0;
      max-width: 70%;
      height: auto;
      @media screen and (max-width: 767px) {
        max-width: 90%;
      }
    }
  }
}
