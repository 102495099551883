.hero {
  background-color: #000;
  height: 100vh;
  position: relative;
  @media screen and (max-width: 575px) {
    height: 50vh;
  }
  @media screen and (min-width: 991px) and (min-height: 991px) {
    height: 991px;
  }

  &__bg {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
    }

    @media screen and (max-width: 575px) {
    }
    @media screen and (min-width: 1419px) {
    }
  }

  &__link {
    display: inline-block;
    position: absolute;
    bottom: 3rem;
    z-index: 10;
    transition: $trans;
    &:hover {
      transform: translateY(1rem);
      transition: $trans;
    }
  }

  &__claim {
    position: relative;
    color: $color-principal;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      justify-content: flex-end;
      padding-bottom: 2rem;
    }
  }
  &__pretitulo {
    font-size: 1rem;
    color: $color-background;
    padding: 5px 10px;
    background-color: $color-principal;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: 767px) {
      font-size: 0.8rem;
      padding: 3px 7px;
    }
  }
  &__titulo {
    font-size: 5.5rem;
    color: $color-background;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 2.5rem;
      line-height: 1.2;
    }
  }
  &__descripcion {
    display: inline-block;
    width: 30%;
    font-size: $font-size;
    color: $color-background;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 1.2rem;
      display: none;
    }
  }

  &__arrow {
    width: 50px;
    height: auto;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.claim {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  &__titulo {
    width: 97% !important;
  }

  &__content {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  &__descripcion {
    text-align: right;
    width: 60%;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: left;
    }
  }
}

.home-euro {
  padding: 2rem 0;
  background-color: $color-gris-01;
  border-bottom: 1px dashed $color-gris-02;
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    @media screen and (max-width: 575px) {
      gap: 1rem;
    }
  }

  &__logo {
    width: 250px;
    height: auto;
  }

  &__content {
    h3 {
      margin: 0;
      font-size: 1.4rem;
    }
  }

  &__cita {
  }
}

.grid-ofertas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  &__picture {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: 1.5rem;
  }

  &__titulo {
    h3 {
      margin-top: 0;
      font-size: 1.3rem;
      font-weight: bold;
      color: $color-principal;
    }
  }

  &__descripcion {
  }
}

.ocasion ul {
  margin-bottom: 6rem;
}

.destacados {
  padding: 2rem 0 3rem 0;
  @media screen and (max-width: 575px) {
    padding: 1rem 0 1rem 0;
  }

  ul.products {
    @media screen and (max-width: 575px) {
      margin-top: 0rem;
    }
  }
  &__container {
  }
  &__content {
  }
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.loop-coches {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  img {
    width: 100%;
    height: auto;
  }
  &--destacados {
  }

  &__item {
    box-shadow: $box-shadow-02;
    padding: 1rem 1rem 2rem 2rem;
    overflow: hidden;
    border-radius: $border-radius;
  }

  &__detalles {
  }

  &__titulo {
    margin-bottom: 0px;
    a {
      color: $color-gris-04;
      font-weight: bold;
      font-size: 1.5rem;
      &:hover {
        color: $color-principal;
      }
    }
  }

  &__datos,
  &__gas {
    font-size: 1.1rem;
    color: $color-gris-02;
  }

  &__precio {
    font-size: 2rem;
    color: $color-principal;
    font-weight: bold;
  }
}

.hero-marquee {
  overflow: hidden;
  white-space: nowrap;
  font-size: 5.9375rem;
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  &--last {
    border-top: none;
  }
}

.marqueeRight,
.marquee {
  font-size: 0;
}
.clipped-text-right,
.clipped-text {
  display: inline-block;
  font-size: 3.5rem;
  text-transform: uppercase;
  padding-right: 1rem;
  line-height: 1.2;
}
.marqueeRight {
  margin-top: 2px;
  .clipped-text-right {
    color: $color-principal;
  }
}

.nosotros {
  &__descripcion {
    font-size: 1.5rem;
    width: 60%;
    margin-bottom: 8rem;
    @media screen and (max-width: 991px) {
      width: 100%;
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  }
}

.empresa {
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 8rem;
  @media screen and (max-width: 991px) {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  img {
    width: 100%;
    height: auto;
  }
  &__col-01 {
  }
  &__col-02 {
    margin-bottom: 2rem;
  }

  &__descripcion {
    width: 60%;
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  &__img {
    width: 300px !important;
    height: auto !important;
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      max-width: 100%;
      width: 100% !important;
      margin-top: 1rem;
    }
  }
}

.marcas {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }

  &__item {
    img {
      width: 45px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 25px;
      }
    }
  }
  &__item--kia {
    img {
      width: auto;
      height: 20px;
      @media screen and (max-width: 767px) {
        width: 40px;
        height: auto;
      }
    }
  }
}

.clientes {
  background-color: $color-principal;
  &__content {
    display: grid;
    grid-template-columns: 8fr auto;
    gap: 27rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__descripcion {
    font-size: 1.5rem;
    color: $color-background;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 50px;
    }
  }

  &__item {
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #fff;
    img {
      width: 25px;
      height: auto;
    }
  }
  &__link {
    color: $color-background;
    font-weight: 500;
    font-size: 1.2rem;
  }
}

// ANTIGUO

.home ul.products.columns-8 li.product {
  width: 15%;
  margin-right: 1rem;
  @media screen and (max-width: 575px) {
    width: 80%;
    margin-right: 1rem;
  }
}

.slider-desk {
  @media screen and (max-width: 575px) {
    display: none;
  }
}

.slider-mobile {
  @media screen and (min-width: 576px) {
    display: none;
  }
}

.manifest {
  padding-top: 2rem;
  &__container {
  }

  &__copy {
    font-size: 1.8rem;
    font-weight: bold;
    width: 60%;
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      width: 100%;
      line-height: 1.8;
    }
    mark {
      background-color: $color-principal;
      color: $color-background;
      @media screen and (max-width: 767px) {
        // background-color: #fff !important;
        // color: $color-gris-04;
        // border-bottom: 4px solid $color-principal;
      }
    }
  }
}

.categories {
  padding: 2rem 0 4rem 0;
  @media screen and (max-width: 575px) {
    padding: 1rem 0 2rem 0;
  }
  ul.products {
    img {
      width: 150px;
      height: 150px;
    }
  }
  .count {
    display: none;
  }
  &__container {
  }
  &__content {
  }
}

.compres {
  padding: 2rem 0;
  &__container {
  }

  &__content {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icono {
    margin-right: 1.5rem;
    @media screen and (max-width: 575px) {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bi {
      font-size: 2rem;
    }
  }

  &__titulo {
    font-weight: 500;
    font-size: $font-size-small;
    span {
      display: block;
      color: $color-gris-02;
    }
  }
}

.quote {
  text-align: center;
  background: url("../assets/site/img-montseny-width.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  color: $color-background;
  position: relative;

  &__overlay {
    position: absolute;
    // background-color: rgba($color: #000000, $alpha: 0.5);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    top: 0;
    bottom: 0;
  }

  &__container {
    padding: 12rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 575px) {
      padding: 5rem 0;
    }
  }

  &__frase {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    width: 70%;
    position: relative;
    z-index: 3;
    @media screen and (max-width: 575px) {
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      width: 90%;
    }
  }

  &__autor {
    position: relative;
    z-index: 3;
  }
}

// .flag {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   &__item {
//     height: 1rem;
//     &--roig {
//       background-color: $color-secundario;
//     }
//     &--groc {
//       background-color: $color-alternativo;
//     }
//     &--lila {
//       background-color: $color-principal;
//     }
//   }

// }

.tipos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    gap: 1rem;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
  @media screen and (max-width: 767px) {
    gap: 2rem;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    overflow-y: scroll;
  }

  &__item {
  }
  &__link {
    &:hover {
      transition: $trans;
      .tipos__img {
        background-color: rgba(0, 0, 0, 0.06);
        transition: $trans;
      }
    }
  }
  &__title {
    font-size: $font-size;
    color: $color-gris-04;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: $font-size-small;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size;
    }
  }
  &__img {
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.04);
    margin-bottom: 1rem;
    transition: $trans;
    @media screen and (max-width: 575px) {
      width: 230px;
      height: auto;
    }
  }
  &__description {
    font-size: $font-size-smaller;
    color: $color-gris-02;
  }
}
