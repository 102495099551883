.g-contacto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  &__contacto {
    margin-top: 1.1rem;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 1rem;
    margin-bottom: 3rem;
  }
  &__concesionario {
  }
  &__titulo {
    text-transform: uppercase;
    display: block;
    color: $color-gris-04;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 1.2rem;
  }
  &__datos {
  }
}

.contactos {
  list-style: none;
  padding-left: 0rem;
  margin-left: 0rem;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    margin-left: 0;
    border: 0;
  }
  li {
    margin-bottom: 0.3rem;
  }
  li strong {
  }
}
