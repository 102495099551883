h1,
h2,
h3,
h4,
h5 {
  line-height: initial;
}

.titulo {
  margin-bottom: 3rem;
  @media screen and (max-width: 575px) {
    margin-bottom: 1.5rem;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__background {
    font-size: $font-size;
    color: $color-background;
    padding: 5px 10px;
    background-color: $color-principal;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    display: inline-flex;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      letter-spacing: 1px;
      padding: 3px 8px;
    }
  }

  &__header-2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3.5rem;
  }
  &__claim {
    width: 60%;
    font-size: 5.5rem;
    text-transform: uppercase;
    line-height: 1;
    @media screen and (max-width: 767px) {
      font-size: 2.5rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    span {
      color: $color-principal;
    }
    &--center {
      align-self: center;
      margin-bottom: 0;
    }
  }
}
